import React,{useEffect, useState} from "react";
import {View,Text,ImageBackground,ScrollView,Image,TouchableOpacity,Modal} from "react-native";
import ReactMarkdown from "react-markdown";
import BounceLoader from "react-spinners/BounceLoader";

import Menu from '../../Menu/menu';
import api from "../../../../Config/api_urls";
import style from "../../../../Styles/CheckIn";
import globalstyle from "../../../../Styles/Globals/globals";


function Checkin(RouterData)
{
    let poiData = RouterData.route.params.itinerari.pois.find(e => e.id == RouterData.route.params.poi.id);
    const punts = 1;
    const [continuar, setContinuar] = useState(false);
    const [loading, setLoading] = useState(false);

    // useEffect(() => {
    //     // document.getElementById('audio').play();
    // }, []);

    const _paintImage = () => {
        if(poiData.Tipus == "Joc360")
        {
            return (
                <iframe src={ poiData.Url_Joc360} allow="fullscreen;geolocation;camera;gyroscope;accelerometer;magnetometer" style={{height: Dimensions.get('screen').height * (13 / 15) * 0.55, width: Dimensions.get('screen').height * (13 / 15) * 0.5, resizeMode: 'contain',borderRadius:5}} />
            )
        }else
        {
          if(continuar)
          {
            return(
                <>
                    <View style={{flex:30,width:'80%',justifyContent:'center',alignItems:'center'}}>
                    <Image
                    resizeMode="contain"
                    style={style.image}
                    source={{uri:api.api_url + poiData.Checkin2.url}}
                    />
                    </View>
                    <View style={[{flex:20,justifyContent:'center',alignItems:'center'},style.descripcio]}>
                        <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} >
                            {poiData['Descripcio'+RouterData.route.params.idioma]  ?
                                <ReactMarkdown >{poiData['Descripcio'+RouterData.route.params.idioma]}</ReactMarkdown>
                            :
                                <ReactMarkdown >{poiData['descripcioDetallada'+RouterData.route.params.idioma]}</ReactMarkdown>
                            }
                            </ScrollView>
                    </View>
                </>
            )
           
          }else
          {
            return(
                <>
                    <View style={{flex:30,width:'80%',justifyContent:'center',alignItems:'center'}}>
                    <Image
                    resizeMode="contain"
                    style={style.image}
                    source={{uri:api.api_url + poiData.Checkin1.url}}
                />
                    </View>
                    <View style={[{flex:20,justifyContent:'center',alignItems:'center'},style.descripcio]}>
                        <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} >
                                <ReactMarkdown >{poiData['descripcioDetallada'+RouterData.route.params.idioma]}</ReactMarkdown>
                            </ScrollView>
                    </View>
                </>
            )
          }
        }

    }

    const _renderBoto = () => {
        if(loading)
        {
            setTimeout(() => {
                setLoading(false);
                setContinuar(true);
            }, 1000);

            return(
                <BounceLoader size={50} color={"#009499"} loading={loading} speedMultiplier={1.5}  />
            )

        }else
        {
            if(continuar)
            {
                return(
                    <TouchableOpacity onPress={()=>{
                        RouterData.route.params.currentItinerari.pois.find(e => e.id == RouterData.route.params.poi.id).punts = punts <= 0 ? 0 : punts;
                        RouterData.navigation.navigate('Premi',RouterData.route.params);
                    }}>
                        <Text style={globalstyle.botoContinuar}>{RouterData.route.params.recursos.find(e=> e.tipus_recurs == "text_continuar")['text_recurs'+RouterData.route.params.idioma]}</Text>
                    </TouchableOpacity>
                )
            }else
            {
                return(
                    <TouchableOpacity onPress={()=>{(
                        setLoading(true)
                    )}}>
                        <Text style={globalstyle.botoContinuar}>{RouterData.route.params.recursos.find(e=> e.tipus_recurs == "check_in")['text_recurs'+RouterData.route.params.idioma]}</Text>
                    </TouchableOpacity>
                )
            }
        }
    }

    return(
        <View style={{flex:1}}>
            <ImageBackground source={{uri: api.api_url + RouterData.route.params.recursos.find(e => e.tipus_recurs == 'background_default').imatge_recurs.url}} style={{flex:1}}>
                <View style={{flex:20,justifyContent:'center',alignItems:'center',alignSelf:'center',width:'90%'}}>
                        <ScrollView contentContainerStyle={style.scroll} showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
                            <View style={{justifyContent: 'center', alignItems: 'center',width:'100%'}}>
                                <Text style={style.titol}> {poiData['Nom'+RouterData.route.params.idioma]} </Text>
                            </View>
                        </ScrollView>
                </View>
                <View style={{flex:5}} />
                <View style={{flex:50,justifyContent:'center',alignItems:'center'}}>
                    {_paintImage()}
                </View>
                {/* <View style={{flex:5}} /> */}
                <View style={{flex:10 , justifyContent:'center',alignItems:'center'}}>
                    {_renderBoto()}
                </View>
                <View style={{flex:10}}>
                    {Menu(RouterData)}
                </View>
            </ImageBackground>
        </View>
    )
}
export default Checkin;
