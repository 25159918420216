import { StyleSheet } from 'react-native';
import { Dimensions } from 'react-native';

import { colorCorporatiu , colorBotons,  } from '../Config/constants'

/* Styles for the component Main */

const style = StyleSheet.create({
    fontFamily: 'Open Sans',

    mainView: {
      flex: 1,
      backgroundColor: '#fff',
      alignItems: 'center',
    },
    loading: {
      flex: 1,
      justifyContent: 'center',
      width : '50%',
      height : '50%',
      resizeMode: 'contain',
    },
    perMesInfo: {
      width: Dimensions.get("screen").height * 0.090,
      height: Dimensions.get("screen").height * 0.110,
      justifyContent:'center',
    },
    ViewPerMesInfo: {
      top: "64%",
      alignSelf:'center',
      position:'absolute'
    },
 
});
    
export default style; 