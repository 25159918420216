import { StyleSheet } from 'react-native';
import { Dimensions } from 'react-native';
import { colorCorporatiu , colorBotons,  } from '../Config/constants'

/* Styles for the component Relats */

const style = StyleSheet.create({

    titol : {
        fontSize: "3.4vh",
        color: "#009499",
        textAlign: "center",
        justifyContent: "center",
        alignSelf: "center",
        fontFamily: "Leckerli One",
        textShadow: "-0.5vh -0.5vh 0.5vh #f2eed7, 0.5vh -0.5vh 0.5vh #f2eed7, -0.5vh 0.5vh 0.5vh #f2eed7, 0.5vh 0.5vh 0.5vh #f2eed7",
        paddingTop:25
    },
    text: {
        width:'90%',
        alignSelf:'center',
        fontSize: "2.5vh",
        textAlign: "center",
        justifyContent: "center",
        fontFamily: 'Gothic',
        paddingTop:'2%',
        // color: "#3d342a"
    },

 
});
 

export default style; 