import React from "react";
import { Modal,View, Text, Image, TouchableOpacity } from "react-native";

import api from "../../../Config/api_urls";
import style from "../../../Styles/popUp";

//todo introduir traduccións al nom del premi
function PopUpPerfilImatge(premi,modalVisible,setModalVisible)
{

    return (
    <TouchableOpacity onPress={()=>{setModalVisible(false)}}>
        <Modal animationType="fade" transparent={true} visible={modalVisible} onBackdropPress={()=>{setModalVisible(false)}} >
            <View style={style.modalOutBackground}>
                <View style={{display: "flex",height: "60%",width: "80%",}}>
                    <Image source={{uri: api.api_url + premi.Premi.url}} style={style.imatgePremiPrefil}/>
                    <Text style={style.textPerfil}>{premi.Nom}</Text>
                </View>
            </View>
        </Modal>
    </TouchableOpacity>
    )
}

export default PopUpPerfilImatge;