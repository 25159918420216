import React from "react";
import { View, Text, ImageBackground, ScrollView, Image, TouchableOpacity, Dimensions } from "react-native";

import api from "../../../Config/api_urls";
import globalstyle from "../../../Styles/Globals/globals";
import style from "../../../Styles/vistaVR";;
import Menu from "../Menu/Menu";
import * as WebBrowser from 'expo-web-browser';

function VistaVR(RouterData) {

    const renderVistes = () => {
        let items = []
        RouterData.route.params.projecte.vistes.map((vista,index) => {
            items.push(
                <View key={index} style={{flexGrow: 0.6, width:'100%',height:'80%',paddingTop:'7%'}}>
                    <Text  style={[style.titol,{fontSize: "3vh",width:'95%',alignSelf:'center',paddingBottom:10}]}>{vista.Titol}</Text>
                    <TouchableOpacity style={{width:'100%',height:'100%',}} onPress={()=>{_handlePressButtonAsync(vista.URL)}}>
                        <Image  style={{ width:'90%',height:'80%',resizeMode:'cover',alignSelf:'center'  }} source={{ uri: api.api_url+ vista.miniatura.url }} />
                        {/* {vista.Tipus == 'Vista360' ? (<Image  style={{ width: 50, height: 50, position: 'absolute', zIndex: 9999, overflow: 'visible', top: '20%', justifyContent: 'center', alignSelf:'center' }} source={require('../../../assets/Images/360.png')} />) : null}
                        {vista.Tipus == 'Dron' ? (<Image style={{ width: 50, height: 50, position: 'absolute', zIndex: 9999, overflow: 'visible', top: '20%', justifyContent: 'center', alignSelf:'center' }} source={require('../../../assets/Images/dron.png')} />) : null} */}
                    </TouchableOpacity>
                </View>
            )
        })
        return items
    }

    const _handlePressButtonAsync = async (link) => {
        let result = await WebBrowser.openBrowserAsync(link);
    };


    return (
        <View style={{flex:1}}>
            <ImageBackground source={{uri: api.api_url +  RouterData.route.params.recursos.find(e => e.tipus_recurs == '05_background_VistesVR')['imatge_recurs' + RouterData.route.params.idioma].url}} style={globalstyle.backgroundImatges}>
                <View style={{flex:30,alignContent:'center',alignSelf:'center',justifyContent:'center',width:'100%',height:'100%'}}>
                    <Image source={{uri: api.api_url +  RouterData.route.params.recursos.find(e => e.tipus_recurs == 'titol_360')['imatge_recurs' + RouterData.route.params.idioma].url}} style={{width:'80%',height:'100%',alignSelf:'center',justifyContent:'center',resizeMode:'contain'}}/>
                </View>
                <View style={{flex:60,alignContent:'center',justifyContent:'center',maxHeight:'100%',width:'100%',height:'100%'}}>
                <ScrollView style={{flexGrow: 0.8}} >
                        {renderVistes()}
                </ScrollView>
                </View>
                <View style={{flex:10}}>
                    {Menu(RouterData)}
                </View>
            </ImageBackground>
        </View>
    );
}

export default VistaVR;