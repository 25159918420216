import { StyleSheet } from 'react-native';
import { Dimensions } from 'react-native';

import { colorCorporatiu , colorBotons,  } from '../Config/constants'

/* Styles for the component VistaVR */

const style = StyleSheet.create({
    titol : {
        fontSize: "4.5vh",
        color: "#009499",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "Gothic",
        fontWeight:'bold',
        textShadow: "-0.5vh -0.5vh 0.5vh #f2eed7, 0.5vh -0.5vh 0.5vh #f2eed7, -0.5vh 0.5vh 0.5vh #f2eed7, 0.5vh 0.5vh 0.5vh #f2eed7"
    },

    image : {
        width: "100%",
        height: 100,
        resizeMode: "contain",
    },
});
    
export default style; 