import { StyleSheet } from 'react-native';
import { Dimensions } from 'react-native';

import { colorCorporatiu , colorBotons,  } from '../Config/constants'

/* Styles for the component Main */

const style = StyleSheet.create({
    fontFamily: 'Open Sans',

    descriptionContainer: {
        width: "95%",
        textAlign: "center",
        alignSelf: 'center',
        fontFamily: 'OpenSans',
        fontSize: "2vh",
        // fontWeight: "bold",
        fontFamily: "Gothic",
        // color: "white"
    },
    textPremi: {
        fontSize: "2vh",
        color: "#009499",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "Leckerli One",
        // textShadow: "-0.5vh -0.5vh 0.5vh #f2eed7, 0.5vh -0.5vh 0.5vh #f2eed7, -0.5vh 0.5vh 0.5vh #f2eed7, 0.5vh 0.5vh 0.5vh #f2eed7"
    },
    punts :{
        flex:40,
        width: "80%",
        textAlign: "center",
        alignSelf: 'center',
        fontFamily: 'OpenSans',
        fontSize: "2.2vh",
        fontWeight: "bold",
        // color: "#3d342a"
    },
});
    
export default style; 