import AsyncStorage from "@react-native-async-storage/async-storage";
import React, { useEffect, useState } from "react";
import { View, Text, ImageBackground, ScrollView, Image, TouchableOpacity, Dimensions } from "react-native";

import api from "../../../Config/api_urls";
import Menu from "../Menu/menu";
import style from "../../../Styles/perfil";
import XarxesSocials from "../PopUps/XarxesSocials";
import getPointsItinerari from "../../Functions/getPointsItinerari";
import constants from "../../../Config/constants";

function Perfil(RouterData) {
    const [nom, setNom] = useState("");
    const [punts, setPunts] = useState(0) 
    const [token, setToken] = useState("");
    const [statsItineraris, setStatsItineraris] = useState([]);

    useEffect(() => {
        
        getPointsItinerari(RouterData.route.params.itineraris).then(data => {
            setStatsItineraris(data);
        }).catch(err => {
            console.log(err);
        })
    
        AsyncStorage.getItem("nom").then(nom => {
            if(nom)
            {
                setNom(nom);
            }
        });
        AsyncStorage.getItem("puntsTotals").then(punts => {
            if(punts)
            {
                setPunts(punts);
            }
        });
        AsyncStorage.getItem("token").then(token => {
            if(token)
            {
                setToken(token);
            }
        });

    },[]);

    const navigateImatges = (itinerari) => {
        let statsPressedItinerari  = statsItineraris.itineraris.find(e => e.itinerari == itinerari.id);
        RouterData.navigation.navigate("ImatgesPerfilItinerari",{...RouterData.route.params, perfilImatges: statsPressedItinerari});
    }

    const renderItineraris = () => {

        let items = []
        RouterData.route.params.itineraris.map((itinerari, index) => {

            let color = constants.colorItinerari
            let textColor = 'black'
            if(statsItineraris.itineraris){
                if(statsItineraris.itineraris[index]?.estat == "finalitzat")
                {
                    color = "#9f2c26"
                    textColor = 'white'
                    
                }else if(statsItineraris.itineraris[index]?.estat == 'comencat')
                {
                    color = "#f4efa2"
                }
            }

            items.push(
            <TouchableOpacity style={style.botoItinerari} key={index} onPress={()=>{navigateImatges(itinerari)}}>
                <View style={[style.itinerariContainer,{backgroundColor : color}]}>
                    <Text style={{fontSize: 14, fontWeight: 'bold', padding: 5}}>
                        <Text style={[style.itinerariText,{color:textColor}]}>{itinerari['Nom'] ? itinerari['Nom'].toUpperCase() : null}</Text>
                    </Text>
                </View>
            </TouchableOpacity>
            )
        })
        return items
    }


    return (
        <View style={{ flex: 1 }}>
                <ImageBackground source={ api.api_url + RouterData.route.params.recursos.find(e => e.tipus_recurs == 'background_default')['imatge_recurs'].url } style={{flex:1, resizeMode: "cover"}}>
                    {/* Box Perfil (NOM) */}
                    <View style={{flex:20,justifyContent:'center',alignItems:'center'}}>
                        <View style={style.boxNom}>
                            <Text style={style.textPerfil}>{RouterData.route.params.recursos.find(e => e.tipus_recurs === 'perfil_nom')['text_recurs' + RouterData.route.params.idioma]}</Text>
                            <Text style={style.nom}>{nom}</Text>
                        </View>
                    </View>
                    {/* Box Perfil (PUNTS) */}
                    <View style={{flex:15,display: "flex", flexWrap: "wrap", flexDirection: "row", justifyContent: 'center'}}>
                        <View style={{width: "40%", height: "90%", justifyContent: "center"}}>
                            <View style={{alignSelf: 'center', backgroundColor: "#f4efa2", alignItems: "center", justifyContent: "center", width: "90%", height: "100%"}}>
                                <Text style={style.SecondTitle}>{RouterData.route.params.recursos.find(e => e.tipus_recurs === 'perfil_punts_totals')['text_recurs' + RouterData.route.params.idioma]}</Text>
                                <Text style={style.SecondText}>{punts}</Text>
                            </View>
                            
                        </View>
                        <View style={{width: "40%", height: "90%", justifyContent: "center"}}>
                            <View style={{alignSelf: 'center', width: "90%", height: "100%", alignItems: "center", justifyContent: "center", backgroundColor: "#f4efa2"}}>
                                <Text style={style.SecondTitle}>{RouterData.route.params.recursos.find(e => e.tipus_recurs === 'perfil_punts_itineraris')['text_recurs' + RouterData.route.params.idioma]}</Text>
                                <Text style={style.SecondText}>{statsItineraris.itinerarisFinalitzat}/{RouterData.route.params.itineraris.length}</Text>
                            </View>
                        </View>
                    </View>

                    {/* Itineraris */}
                    <View style={{flex:45,alignItems:'center',justifyContent:'center',width:'100%',alignItems: "center", justifyContent: "center",flexDirection:'row'}}>
                        
                        <ScrollView style={style.caixa}>
                            <View style={{
                                justifyContent: 'center',
                                flexGrow: 0,
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap'
                            }}>
                                {renderItineraris()}
                            </View>
                        </ScrollView>
                    </View>

                    {/* Compartir xarxes */}
                    <View style={{flex:10,justifyContent:'center',alignItems:'center'}}>
                        <View style={{flex:1,justifyContent:'center',alignItems:'center',flexDirection:'row'}}>
                            <TouchableOpacity style={{height: (Dimensions.get('screen').height * (13 / 15) * 0.67) * 0.11, width: (Dimensions.get('screen').height * (13 / 15) * 0.67) * 0.11}} onPress={
                            ()=>{RouterData.navigation.navigate('Credits',RouterData.route.params)}}>
                                <Image style={{width:'100%',height: "90%",alignSelf:'center',resizeMode:'contain'}} source={{uri: api.api_url + RouterData.route.params.recursos.find(e=> e.tipus_recurs == "perfil_contacte")["imatge_recurs"].url}} />    
                            </TouchableOpacity>
                            {XarxesSocials(RouterData,token,{textSocial : encodeURI(RouterData.route.params.projecte['textSocial'+RouterData.route.params.idioma]).replaceAll('#', '%23'), peticio : "Global" })}
                        </View>
                    </View>
                    {/* Menu */}
                    <View style={{flex:10}}>
                        {Menu(RouterData)}
                    </View>
                </ImageBackground>
        </View>
    )
}

export default Perfil;