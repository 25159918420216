import React, { useEffect } from "react";
import { View, Text, ImageBackground, ScrollView, Image, TouchableOpacity, Dimensions } from "react-native";

import api from "../../../Config/api_urls";
import Menu from "../Menu/Menu";
import style from "../../../Styles/trucada";


function Trucada(RouterData)
{
    // useEffect(() => {
    //     document.getElementById('audio2').play()
    // } , [])

    const closeCall=()=>{
        RouterData.navigation.navigate("Mapa",RouterData.route.params);
    }

    RouterData.navigation.addListener('blur',()=>{ 
        // document.getElementById('audio2').remove('audio')
    });


        return (
          <View style={{ flex: 1 }}>
            <View
              style={{
                height: "100%",
                width: "100%",
                backgroundColor: "black",
                alignSelf: "center",
                justifyContent: "center",
              }}
            >
              <div id="container">
                <div id="audioTrucada"></div>
                <video
                  id="video"
                  autoPlay
                  style={{
                    height: "100%",
                    width: "100vw",
                    resizeMode: "cover",
                    objectFit: "cover",
                    position: "fixed",
                    left: 0,
                    top: 0,
                    zIndex: -1,
                  }}
                >
                  <source
                    id="videoSource"
                    src={require("../../../assets/Video/trucada.mp4")}
                    type="video/mp4"
                  />
                </video>
              </div>
            </View>
            <View
              style={{
                flex: 0.05,
                bottom: 25,
                left: 0,
                right: 0,
                position: "fixed",
              }}
            >
              {/*era 70 */}
              <View style={{ flexDirection: "row", width: "100%" }}>
                <Image
                  source={require("../../../assets/Images/cam.png")}
                  style={{
                    height: '5.5rem',
                    width: "33%",
                    resizeMode: "contain",
                    alignSelf: "center",
                  }}
                />
                <TouchableOpacity
                  style={{ width: "33%" }}
                  onPress={() => {
                   RouterData.navigation.navigate("Mapa",RouterData.route.params);
                  }}
                >
                  <Image
                    source={require("../../../assets/Images/closeCall.png")}
                    style={{
                      height: '5.5rem',
                      width: "100%",
                      resizeMode: "contain",
                      alignSelf: "flex-start",
                    }}
                  />
                </TouchableOpacity>
                <Image
                  source={require("../../../assets/Images/miss.png")}
                  style={{
                    height: '5.5rem',
                    width: "33%",
                    resizeMode: "contain",
                    alignSelf: "flex-end",
                  }}
                />
              </View>
            </View>
          </View>
        );

}

export default Trucada;


//   {/* Nom   */}
