import react, { useState } from "react";
import { ImageBackground, Text, TextInput, TouchableOpacity, View } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";

import globalstyle from "../../../Styles/Globals/globals";
import style from "../../../Styles/login";
import api from "../../../Config/api_urls";
import constants from "../../../Config/constants";
import Menu from "../Menu/Menu";
import { setAnalyticsCollectionEnabled } from 'expo-firebase-analytics';


function Login(RouterData)
{
    const [nom, setNom] = useState("");


    const send = () => {
        if(nom.length > 0)
        {
            AsyncStorage.setItem("nom", nom);
            AsyncStorage.setItem('puntsTotals', 0);
            setAnalyticsCollectionEnabled(true)

            RouterData.navigation.navigate("InfoItinerari", { projecte: RouterData.route.params.projecte, recursos : RouterData.route.params.recursos, premisAlternatius : RouterData.route.params.premisAlternatius,idioma: RouterData.route.params.idioma, itineraris: RouterData.route.params.itineraris, premis: RouterData.route.params.premis, pois: RouterData.route.params.pois, itinerari : RouterData.route.params.itineraris[0]  });
        }
    }
    
    return (
        <View style={{flex:1}}>
            <ImageBackground source={{uri: api.api_url + RouterData.route.params.recursos.find(e => e.tipus_recurs == '03_background_login')['imatge_recurs' + RouterData.route.params.idioma].url}} style={globalstyle.backgroundImatges}>
                <View style={{flex:80}}>
        
                </View>
                <View style={{flex:20}}>
                    <TextInput
                        autoCorrect={false}
                        placeholder={RouterData.route.params.recursos.find(e => e.tipus_recurs === 'nom_login')["text_recurs" + RouterData.route.params.idioma]}
                        placeholderTextColor={ constants.colorText }
                        returnKeyType="send"
                        maxLength={20}
                        onChangeText={text => setNom(text)}
                        style={style.textNom}
                        value={nom}
                    />
                    <TouchableOpacity onPress={() => { send() }} style={{paddingTop:"2%"}} >
                            <Text style={[globalstyle.boto,{color:'#000'}]}>{RouterData.route.params.recursos.find(e => e.tipus_recurs === 'boto_login')["text_recurs"+ RouterData.route.params.idioma]}</Text>
                    </TouchableOpacity>
                </View>
            </ImageBackground>
        </View>
    );
}


export default Login;