import { StyleSheet } from 'react-native';
import constants from '../Config/constants';

/* Styles for the component Login */

const style = StyleSheet.create({
    fontFamily: 'Open Sans',

  textNom: {
    fontSize: "3.5vh",
    width: '80%',
    height: 40,
    justifyContent: "center",
    alignSelf: 'center',
    borderRadius: 1,
    textAlign: 'left',
    fontWeight: 'bold',
    backgroundColor: 'white',
    color: constants.colorText,
    paddingLeft: 10,
  },
  videoContainer : {
    height: '100%',
    width: '100%',
    backgroundColor: 'black',
    alignSelf: 'center',
    justifyContent: 'center',
  },
  descriptionContainer: {
    width: "80%",
    textAlign: "center",
    alignSelf: 'center',
    fontFamily: 'OpenSans',
    fontSize: "2.2vh",
  },
  
cross: {
  height: 30,
  width: 30
}
 

});
    
export default style; 