import * as Location from 'expo-location';

async function getLocation()
{
    let { status } = await Location.requestForegroundPermissionsAsync();
    if (status !== 'granted') {
      console.log('Permission to access location was denied');
      return;
    }
    Location.watchPositionAsync({
        enableHighAccuracy: true,
        timeInterval: 2000,
        distanceInterval: 4,
        accuracy: Location.Accuracy.BestForNavigation,
    }, async (location) => {
        console.log('====================================');
        console.log(location);
        console.log('====================================');
    });
}

export default getLocation;