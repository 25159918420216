import React, { useEffect } from "react";
import {View, Text, Dimensions, Image, TouchableOpacity, ScrollView, ActivityIndicator } from "react-native";

import style from "../../../Styles/trucada";
import api from "../../../Config/api_urls";

function PreviaTrucada(RouterData)
{
    useEffect(() => {
        document.getElementById('audio').play()
    } , [])


    const closeCall=()=>{
        RouterData.navigation.navigate("Mapa",RouterData.route.params);
    }
    const goCall=()=>{
        RouterData.navigation.navigate('Trucada',RouterData.route.params);
    }

    RouterData.navigation.addListener('blur',()=>{ 
        document.getElementById('audio').remove('audio')
    });

    return(
        <View style={{flex:1,backgroundColor:'#000'}}>
            <View style={{flex:40,justifyContent:'center'}}>
                <View style={{flex:1,alignSelf:'center',width:'90%',height:'100%',flexDirection:'row'}}>
                    {/* <Image style={{width:"50%",height:"50%",resizeMode:'contain',alignSelf:'center'}} source={{uri : api.api_url + RouterData.route.params.itinerari.Imatge_finalitzat.url}}/> */}
                    <View style={{flex:1,alignSelf:'center',width:'100%',}}>
                        <Text style={[style.text,{fontSize:'4.8vh',alignSelf:'center'}]}>{RouterData.route.params.itinerari["Nom"+RouterData.route.params.idioma]}</Text> 
                       <View style={{flexDirection:'row',flex:1,alignSelf:'center',width:'100%'}}>
                       {/* <Image style={{width:"20%",height: Dimensions.get('screen').height * 0.02,resizeMode:'contain'}} source={require("../../../assets/Images/vallSapIcona.png")}/>
                        <Text style={[style.text,{fontSize:'1.5vh',justifyContent:'center'}]}>{RouterData.route.params.recursos.find(e => e.tipus_recurs == 'text_previ_trucada')["text_recurs"+ RouterData.route.params.idioma]}</Text>  */}
                       </View>
                    </View>
                </View>
            </View>
            <View style={{flex:20}}/>
            
            {/* Dummy icones */}
            <View style={{flex:10}}>
                {/* <View style={{flex:1,alignSelf:'center',width:'100%',height:'100%',flexDirection:'row',alignContent:'center'}}>
                    <View style={{flex:1,alignSelf:'center',width:'100%',height:'100%',alignContent:'center'}}>
                        <Image style={{width:"50%",height:"40%",resizeMode:'contain',alignSelf:'center'}} source={require("../../../assets/Images/volumenIcon.png")}/>
                        <Text style={[style.text,{fontSize:'2vh'}]} >Volumen</Text>
                    </View>
                    <View style={{flex:1,alignSelf:'center',width:'100%',height:'100%',alignContent:'center'}}>
                        <Image style={{width:"50%",height:"40%",resizeMode:'contain',alignSelf:'center'}} source={require("../../../assets/Images/muteIcon.png")}/>
                        <Text style={[style.text,{fontSize:'2vh'}]} >Silencia</Text>
                    </View>                
                </View> */}
            </View>

            {/* Agafar/penjar icones  */}
            <View style={{flex:20}}>
                <View style={{flex:1,alignSelf:'center',width:'100%',height:'100%',flexDirection:'row',alignContent:'center'}}>
                <TouchableOpacity onPress={()=>{goCall()}} style={{width:"50%",height:'60%',alignSelf:'center'}}>
                    <Image style={{width:"100%",height:"100%",resizeMode:'contain'}} source={require("../../../assets/Images/mobile.gif")}/>
                </TouchableOpacity>
                <TouchableOpacity onPress={()=>{closeCall()}} style={{width:"50%",height:'60%',alignSelf:'center'}}>
                    <Image style={{width:"100%",height:"100%",resizeMode:'contain'}} source={require("../../../assets/Images/closeCallIcon.png")}/>
                </TouchableOpacity>
                </View>
            </View>
            <audio id="audio" src={require('../../../assets/Audios/call.mp3')} loop  ></audio>
        </View>
    )
}

export default PreviaTrucada;