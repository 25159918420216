import React,{useState} from "react";
import {View,Text,ImageBackground,Image,TouchableOpacity,Modal} from "react-native";

import style from "../../../Styles/popUp";
import api from "../../../Config/api_urls";
import globalstyle from "../../../Styles/Globals/globals";
import constants from "../../../Config/constants";
import XarxesSocials from "./XarxesSocials";
import getPointsItinerari from "../../Functions/getPointsItinerari";


// todo boto per superPremi 
function PopUpFinal(RouterData,modalVisible,setModalVisible,token)
{
    const navigateMostrarInventari = async () => { 
        let item = await getPointsItinerari(RouterData.route.params.itineraris)
        let statsPressedItinerari  = item.itineraris.find(e => e.itinerari == RouterData.route.params.currentItinerari.id);
        RouterData.navigation.navigate("ImatgesPerfilItinerari",{...RouterData.route.params, perfilImatges: statsPressedItinerari});
    }

    return(
        <Modal animationType="fade" transparent={true} visible={modalVisible}>
            <View style={style.modalOutBackground}>
                <View style={[style.caixaPopUp,{height:"80%"}]}>
                    <ImageBackground source={{uri: api.api_url + RouterData.route.params.recursos.find(e => e.tipus_recurs == '05_background_tipus2_confeti')['imatge_recurs'].url}} style={style.backgroundImatges}>
                        <TouchableOpacity style={globalstyle.crossBox} onPress={()=>{setModalVisible(false)}}>
                            <Image source={{uri: api.api_url + RouterData.route.params.recursos.find(e => e.tipus_recurs == 'pop_up_close_icon')['imatge_recurs'].url }} style={style.cross} />
                        </TouchableOpacity>
                        <View style={{flex:40,alignItems:'center',width:'85%',justifyContent:'center'}}>
                            <Text style={[style.popUpTitleInicial]}> {RouterData.route.params.recursos.find(e => e.tipus_recurs == 'text_finalitzacio_itinerari')['text_recurs'+ RouterData.route.params.idioma]}</Text>
                        </View>
                        <View style={{flex:50,alignItems:'center',width:'85%',justifyContent:'center'}}>
                            <TouchableOpacity onPress={()=>{navigateMostrarInventari()}} >
                                <Text style={globalstyle.botoContinuar}>{RouterData.route.params.recursos.find(e => e.tipus_recurs == "text_mostrar_inventari")['text_recurs'+RouterData.route.params.idioma]}</Text>
                            </TouchableOpacity>
                        </View>         
                           {XarxesSocials(
                                RouterData,
                                token,
                                { textSocial : encodeURI(RouterData.route.params.itinerari['textSocial'+RouterData.route.params.idioma]).replaceAll('#', '%23'), peticio : RouterData.route.params.itinerari.Nom }
                            )}
                    </ImageBackground>
                </View>
            </View>
        </Modal>
    )
}

export default PopUpFinal;