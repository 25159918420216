import { StyleSheet } from "react-native";
import { Dimensions } from "react-native";

import constants, { colorCorporatiu, colorBotons } from "../Config/constants";

/* Styles for the component Perfil */

const style = StyleSheet.create({
  textPerfil: {
    fontSize: "2.5vh",
    textAlign: "center",
    // color: "#878c90",
    fontFamily: "Ghotic",
    fontWeight: "600",
  },
  nom: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "3vh",
    // color: "#715f4d",
    fontFamily: "Ghotic",
  },
  nomIter: {
    textAlign: "center",
    fontWeight: "600",
    fontSize: "3vh",
    // color: "#715f4d",
    fontFamily: "Ghotic",
  },
  boxNom: {
    flex: 1,
    justifyContent: "center",
    width: "80%",
    maxHeight: "80%",
    // borderColor: "#878c90",
    borderWidth: 8,
    backgroundColor: "#f4efa2",
  },
  SecondTitle: {
    // color: "#878c90",
    fontSize: "2.5vh",
    textAlign: "center",
    fontWeight: "600",
    fontFamily: "Ghotic",
  },
  SecondText: {
    // color: "#715f4d",
    fontSize: "2vh",
    textAlign: "center",
    fontWeight: "bold",
    fontFamily: "Ghotic",
  },
  botoItinerari: {
    width: "50%",
    justifyContent: "center",
  },
  itinerariText: {
    fontSize: 15,
    textAlign: "center",
    fontWeight: "bold",
    width: '100%',
    alignSelf: 'center',
    // color : constants.colorTextItinerari,
},
itinerariContainer: {
    paddingTop: 5,
    paddingBottom: 5,
    width: "80%",
    textAlign: "center",
    alignSelf: 'center',
    marginTop: 7,
    marginBottom: 7,
    marginLeft: 7,
    marginRight: 7,
    backgroundColor: constants.colorItinerari,
    borderRadius: 10,
},

caixa: {
    maxHeight: "100%",
    width: "100%",
    maxWidth: "100%"
},
image : {
    width: "100%",
    height: "100%",
    resizeMode: "contain",
},
premiImage : {
    height: Dimensions.get('screen').height * 0.1,
    width: Dimensions.get('screen').width * 0.2,
    resizeMode: "contain",
    flexDirection: 'row',
    alignSelf:'center',
},
premiContainer : {
  width:"33.3%",
  alignItems: 'center',
  justifyContent: 'center',
  padding:10,
},

superPremiContainer : {
    width:"100%",
    height: "100%",
    alignItems: 'center',
    justifyContent: 'center',
    padding:10,
},

videoContainer : {
  height: '100%',
  width: '100%',
  backgroundColor: 'black',
  alignSelf: 'center',
  justifyContent: 'center',
},

});

export default style;
