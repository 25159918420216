import reac,{useEffect, useState} from "react";
import { View,ImageBackground,TouchableOpacity,Text,Image} from "react-native";
import { Modal } from "react-native-web";

import style from "../../../Styles/popUp";
import api from "../../../Config/api_urls";
import globalstyle from "../../../Styles/Globals/globals";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { postSatisfaccio } from "../../../Config/petitions";

function renderPopUp(RouterData,itinerari)
{
    const [modalVisible, setModalVisible] = useState(true); 
    const [token, setToken] = useState("");
    useEffect(() => {
        AsyncStorage.getItem("user_token").then(token => {
            if(token)
            {
                setToken(token);
            }
        });
    },[]);

    const closePopUp = () => {
        setModalVisible(false);
        AsyncStorage.setItem("finalitzacio_itinerari", "");
    }
//    if(modalVisible){
   return(
        <Modal animationType="fade" transparent={true} ariaHideApp={false} visible={modalVisible} >
            <View style={style.modalOutBackground}> 
                <View style={style.caixaPopUp}>
                    <ImageBackground source={{uri: api.api_url + RouterData.route.params.recursos.find(e => e.tipus_recurs == '05_background_tipus2')['imatge_recurs' + RouterData.route.params.idioma].url}} style={style.backgroundImatges}>
                        {/* Close button */}
                        <TouchableOpacity style={globalstyle.crossBox} onPress={()=>{closePopUp()}}>
                            <Image source={{uri: api.api_url + RouterData.route.params.recursos.find(e => e.tipus_recurs == 'pop_up_close_icon')['imatge_recurs'].url }} style={style.cross} />
                        </TouchableOpacity>
                        {/* Bafarada amb text */}
                        <Image style={style.bafarada} source={{uri: api.api_url + RouterData.route.params.recursos.find(e => e.tipus_recurs === 'satisfaccio_text')["imatge_recurs"+ RouterData.route.params.idioma].url}} />
                        {/* Satisfaccio images */}
                        {/* Content */}
                        <TouchableOpacity style={style.TouchableSatisfaccio} 
                            onPress={()=>{postSatisfaccio({token:token,valoracio : "10",joc : itinerari});closePopUp()}}>
                                 <Image style={style.ImgSatisfaccio}
                                       source={{uri: api.api_url + RouterData.route.params.recursos.find(e => e.tipus_recurs === 'satisfaccio_3').imatge_recurs.url}} />
                        </TouchableOpacity>                        
                        {/* Mitg-mitg */}
                        <TouchableOpacity style={style.TouchableSatisfaccio} 
                            onPress={()=>{postSatisfaccio({token:token,valoracio : "5",joc : itinerari});closePopUp()}}>
                                 <Image style={style.ImgSatisfaccio}
                                       source={{uri: api.api_url + RouterData.route.params.recursos.find(e => e.tipus_recurs === 'satisfaccio_2').imatge_recurs.url}} />
                        </TouchableOpacity>
                        {/* Enfadat */}
                        <TouchableOpacity style={style.TouchableSatisfaccio} 
                            onPress={()=>{postSatisfaccio({token:token,valoracio : "1",joc : itinerari});closePopUp()}}>
                                 <Image style={style.ImgSatisfaccio}
                                       source={{uri: api.api_url + RouterData.route.params.recursos.find(e => e.tipus_recurs === 'satisfaccio_1').imatge_recurs.url}} />
                        </TouchableOpacity>

                    </ImageBackground>
                </View>
            </View>    
        </Modal>
   )
//    }else{
//          return null;
//     }

}

export default renderPopUp;