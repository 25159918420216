import { Dimensions, StyleSheet } from "react-native";
import constants from "../Config/constants";

/* Styles for the component Home */

const style = StyleSheet.create({
  fontFamily: "Open Sans",

  backgroundImatges: {
    flex: 1,
    resizeMode: "cover",
    justifyContent: "center",
  },
  image: {
    width: "100%",
    height: "100%",
  },
  descripcio: {
    width: "80%",
    textAlign: "center",
    alignSelf: 'center',
    fontFamily: 'Gothic',
    fontSize: "2vh",
  },
  questionBackground: {
    width:'80%',
    height: "80%",
    justifyContent: "center",
    backgroundColor: "white",
    borderRadius: 15,
    paddingVertical: 7,
},

titol : {
  fontSize: "3vh",
  // color: "#009499",
  textAlign: "center",
  justifyContent: "center",
  alignItems: "center",
  fontFamily: "Gothic",
  fontWeight:'bold',
  textShadow: "-0.5vh -0.5vh 0.5vh #f2eed7, 0.5vh -0.5vh 0.5vh #f2eed7, -0.5vh 0.5vh 0.5vh #f2eed7, 0.5vh 0.5vh 0.5vh #f2eed7"
},

scroll : {
  justifyContent:'center',
  flexGrow:1,
  width:'100%',
}, 

});

export default style;
