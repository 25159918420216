import AsyncStorage from "@react-native-async-storage/async-storage";
import react, { useEffect, useState } from "react";
import { View, Text,TouchableOpacity, Image, ScrollView, ImageBackground} from "react-native";
import renderPopUp from "../PopUps/popUpSatisfaccio";

import style from "../../../Styles/Home";
import api from "../../../Config/api_urls";
import Menu from "../Menu/menu";

function Home(RouterData) {

    const [itinierari, setItinerari] = useState("");

    const navigation = (route) => {
        RouterData.navigation.navigate(route,RouterData.route.params);
    }

    const renderMenuIcon = (navigate, icona_url) => {
        if(icona_url)
        {
            return (
                <View style={{flex: 12}}>
                    <TouchableOpacity style={style.touchableIcon} onPress={()=>{ navigation(navigate) }} >
                        <Image style={style.menuIcon} source={{uri: api.api_url + icona_url}} />
                    </TouchableOpacity>
                </View>
            );
        }
    }

    useEffect(() => {
        AsyncStorage.getItem("finalitzacio_itinerari").then(itinerari => {
            if(itinerari)
            {
                setItinerari(itinerari);
            }
        });
    },[]);

    return (
        <View style={{ flex: 1 }}>
           {/* Mostrar popUp satisfaccio Usuari  */}
           {itinierari != "" ? renderPopUp(RouterData) : null}
          
           <ImageBackground
                source={{uri: api.api_url + RouterData.route.params.recursos.find(e => e.tipus_recurs == '04_background_principal')['imatge_recurs' + RouterData.route.params.idioma].url}}
                style={style.backgroundImatges}>
                <View style={{flex: 1, width: "100%"}}>
                    
                    {/* Titol */}
                    <View style={{flex: 30}}>

                    </View>
                    
                    {/* Body */}
                    {/*renderMenuIcon('Itineraris',RouterData.route.params.recursos.find(e => e.tipus_recurs == 'boto_home_itineraris')['imatge_recurs' + RouterData.route.params.idioma].url)}
                    {renderMenuIcon('VistaVR',RouterData.route.params.recursos.find(e => e.tipus_recurs == 'boto_home_360')['imatge_recurs' + RouterData.route.params.idioma].url)}
                    {renderMenuIcon('Relats',RouterData.route.params.recursos.find(e => e.tipus_recurs == 'boto_home_relats')['imatge_recurs' + RouterData.route.params.idioma].url)}
    {renderMenuIcon('Perfil',RouterData.route.params.recursos.find(e => e.tipus_recurs == 'boto_home_perfil')['imatge_recurs' + RouterData.route.params.idioma].url)*/}
                    
                    {/* Footer */}
                    <View style={{flex:5}}/>   
                    <View style={{flex:4}}>
                        {Menu(RouterData)}
                    </View>
                </View>
            </ImageBackground> 
        </View>
    );
}

export default Home;