import React from "react";
import {RLayerVector , RStyle,RLayerTileWebGL} from "rlayers";


function RenderLayout(RouterData)
{
    let items = []    
    for(let layout of RouterData.route.params.itinerari.mapes.layouts)
        {
            if(layout.tipus == 'mapa')
            {
              items.push(
                <RLayerTileWebGL
                    key={layout.id}
                    url={layout.url}
                    properties={{label: "IternaturaStyle"}}
                />
              )
            }else if(layout.tipus == 'track')
            {
                items.push(
                    <RLayerVector
                        features={new GeoJSON({
                            featureProjection: "EPSG:3857",
                        }).readFeatures(geojsonFeatures)}
                        key={unique_id2++}
                    >
                        <RStyle.RStyle>
                            <RStyle.RStroke color={RouterData.route.params.itinerari.color_itinerari}
                                            width={3}/>
                            <RStyle.RFill color="transparent"/>
                        </RStyle.RStyle>
                    </RLayerVector>
                )
            }
        }
    return items;    
}


export default RenderLayout;