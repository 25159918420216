import React from "react"
import * as RNLocalize from "react-native-localize"
import * as Device from 'expo-device';
/* Get divice by promise */
/* NOT IMPLEMENTED ! */

async function getDevice() {
  let type = await Device.getDeviceTypeAsync()
    // return(type);
    switch (type) {
        case 0:
            console.log("unknown");
            return("unknown");
        case 1:
            console.log("phone");
            return("phone");
        case 2:
            console.log("tablet");
            return("tablet");
        case 3:
            console.log("desktop");
            return("desktop");
        default:
            console.log("unknown");
            return("unknown");
    }
}

export default getDevice;
