import AsyncStorage from "@react-native-async-storage/async-storage";
import react, { lazy, useEffect,useState } from "react";
import {View, Text, Dimensions,Image} from "react-native";

import { Feature } from "ol";
import { Coordinate } from "ol/coordinate";
import {Geometry, Point, Polygon} from "ol/geom";
import {addCoordinateTransforms, fromLonLat} from "ol/proj";
import {getDistance} from 'ol/sphere';
import {RMap, RLayerTileWebGL, RLayerVector, RFeature, RStyle, RGeolocation, ROverlay,RControl } from "rlayers";

import FlashMessage, { showMessage,hideMessage } from "react-native-flash-message";
import PopUpInicial from "../PopUps/PopUpMapaInicial";
import PopUpMapa from "../PopUps/PopUpMapa";
import PopUpMapaHistoria from "../PopUps/PopUpMapaHistoria";
import Menu from "../Menu/Menu"
import PopUpFinal from "../PopUps/PopUpFinal";
import * as Location from 'expo-location';
import constants from "../../../Config/constants";
import api from "../../../Config/api_urls";
import PoisMapa from "./Pois";
import RenderLayout from "./Layouts";
import React from "react";
/*
    llibreria de mapa https://mmomtchev.github.io/rlayers/api/
*/


function Mapa(RouterData){
    const [location, setLocation] = useState(fromLonLat([2.452541,42.120330]));//fromLonLat([1.6725558042526245,41.20839165286224])
    const [pointLocation, setPointLocation] = useState(fromLonLat([2.452541,42.120330]));//fromLonLat([1.6725558042526245,41.20839165286224])
    const [width, setWidth] = useState(Dimensions.get('window').width);
    const [height, setHeight] = useState(Dimensions.get('window').height * 0.899);
    const [loaded, setLoaded] = useState(false);
    const [ModalIniciVisible, setModalIniciVisible] = useState(true);
    const [ModalHistoria,setModalHistoria] = useState(false);
    const [ModalFinal,setModalFinal] = useState(true);
    const [features, setFeatures] = useState([]);
    const [ currentPoi, setCurrentPoi] = useState(null);
    /*Token per el popUpFinal i altres peticions per analitiques */
    const [token, setToken] = useState("");
    const [angle, setAngle] = useState(0);
    const [deviceOrientation, setDeviceOrientation] = useState(null);

    

    window.addEventListener('resize', () => {
        setWidth(Dimensions.get('window').width );
        setHeight(Dimensions.get('window').height * 0.899);
    });


    // get devuice absolute orientation
    // window.addEventListener('deviceorientationabsolute', (e) => {
    //     console.log('====================================');
    //     console.log(e.alpha);
    //     console.log('====================================');
    //     setDeviceOrientation(e.alpha);
    // });
    
    
    
    useEffect(() => {

        AsyncStorage.getItem('token').then(token => {
            if(token)
            {
               setToken(token);
            }
            if(RouterData.route.params.currentItinerari.comencat)
            {
                setModalIniciVisible(false);
            }

            // Gyroscope.setUpdateInterval(10000);
            // Gyroscope.addListener(gyroscopeData => {
            //     console.log(gyroscopeData);
            // });
        })
        
        
        RouterData.navigation.addListener('focus', async ()=>{ 
            await Location.enableNetworkProviderAsync();
    
            let { status } = await Location.requestForegroundPermissionsAsync();
            if (status !== 'granted') {
              console.log('Permission to access location was denied');
              return;
            }
          
            let location2 = await Location.getCurrentPositionAsync({});
            setLocation(fromLonLat([location2.coords.longitude, location2.coords.latitude]));
            // setAngle(getAngleCompass(RouterData.route.params.currentItinerari.pois,[location2.coords.longitude, location2.coords.latitude],RouterData.route.params.currentItinerari.currentOrder));

            if(status)
            {
                setLoaded(true);
            }
        });
        RouterData.navigation.addListener('blur',()=>{ 
            setLoaded(false);
        });
        initMapa();
      }, []);

    

    const initMapa = async () => {
        RouterData.route.params.currentItinerari.pois.forEach(element => { // Crear una feature per cada poi
            let F =  new Feature({
                geometry: new Point(fromLonLat([parseFloat(element.location[0]),parseFloat(element.location[1])])),
                uid: element.id,
                name: element.Nom,
                id: element.id,
            })
            features.push(F)
        });
        setFeatures([...features]);
        checkPoiAprop;
    }

    const checkPoiAprop = setInterval(() => {
        RouterData.route.params.currentItinerari.pois.forEach(poi => {

            if(!poi.done)
            {

                if(poi.order == RouterData.route.params.currentItinerari.currentOrder && poi.popUpLoaded == false)
                {
                    let distance = getDistance([parseFloat(poi.location[0]),parseFloat(poi.location[1])],location);
                    let distanciaActivacio = RouterData.route.params.itinerari.pois.find(e => e.id == poi.id).metresActivacio ? RouterData.route.params.itinerari.pois.find(e => e.id == poi.id).metresActivacio : 20;
                    //  let distanciaActivacio = 10000000; //todo HERE
                    if(distance <= distanciaActivacio)
                    {
                        setCurrentPoi(poi.id);

                        setModalHistoria(true);
                        clearInterval(checkPoiAprop);
                        poi.popUpLoaded = true;
                    }
                }
            }
        })
    }, 2000);


    const _renderTrackPosition =() => {
        return (
            <RGeolocation //=> d'aquesta manera s'evita el hack de pokemon go (flaying)
                tracking={true}
                 trackingOptions={{ enableHighAccuracy: true, maximumAge: 2000, timeout: 5000 }}
                onChange={(e) => {
                   setPointLocation(fromLonLat([e.target.position_[0],e.target.position_[1]])); // per posicionar el punt
                   setLocation(e.target.position_); // per posicionar el mapa 

                }}
            />
        )
    }
    
    if(!loaded)
    {
        return (
            <View style={{flex:1,justifyContent:'center',alignItems:'center',backgroundColor:constants.colorFonsMenu}}>
                <Text>Loading...</Text>
            </View>
        )
    }else{
    return(
        
        <View style={{flex:1,backgroundColor:constants.colorFonsMenu}}>
            {/* Es carrega el hook abans per evitar problemes  */}
            { RouterData.route.params.currentItinerari.comencat ? null : PopUpInicial(RouterData,ModalIniciVisible,setModalIniciVisible) } 
            { ModalHistoria && !ModalIniciVisible ?
                RouterData.route.params.itinerari.pois.find(e => e.id == currentPoi).teHistoria ? 
                    PopUpMapaHistoria(RouterData,RouterData.route.params.itinerari.pois.find(e => e.id == currentPoi),ModalHistoria,setModalHistoria)
                    : PopUpMapa(RouterData,RouterData.route.params.itinerari.pois.find(e => e.id == currentPoi),ModalHistoria,setModalHistoria) 
                : null }
                
            {RouterData.route.params.currentItinerari.finalitzat ? PopUpFinal(RouterData,ModalFinal,setModalFinal,token) : null} 
            <View>
                <RMap
                    width={width}
                    height={height}
                    initial={{center: location ? location : fromLonLat([1.89572,41.82411]), zoom: 15}}
                    maxZoom={20}
                    noDefaultControls={true}
                >
                   

                    {RenderLayout(RouterData)}
                    
                    <RLayerVector zindex={10}>
                        <RStyle.RStyle>
                            <RStyle.RIcon
                                src={api.api_url + RouterData.route.params.recursos.find(e => e.tipus_recurs == 'geolocalitzacio').imatge_recurs.url}
                                scale={0.08}
                            />
                        </RStyle.RStyle>
                        <RFeature
                            geometry={new Point(pointLocation)}
                        />
                    </RLayerVector>

                    {_renderTrackPosition()}
                    {PoisMapa(RouterData,features,location)}

                </RMap>
                <FlashMessage position="top" />
            </View>
            <View style={{flex:10}}>
                    {Menu(RouterData)}
                </View>
        </View>
    )
    }
}

export default Mapa;


// {PopUpInicial(RouterData)}
// {PopUpMapa(RouterData,RouterData.route.params.itinerari.pois[0])}
// {PopUpMapaHistoria(RouterData,RouterData.route.params.itinerari.pois[0])}
// {PopUpFinal(RouterData)}