import AsyncStorage from "@react-native-async-storage/async-storage";
import React, { useEffect, useState } from "react";
import { View, Text, ImageBackground, ScrollView, Image, TouchableOpacity, Dimensions } from "react-native";

import api from "../../../Config/api_urls";
import Menu from "../Menu/menu";
import style from "../../../Styles/perfil";
import PopUpPerfilImatge from "../PopUps/PopUpPerfilImatge";
import globalstyle from "../../../Styles/Globals/globals";


function ImatgesPerfilItinerari(RouterData) {
    const [premis , setPremis] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [ShowPremi, setShowPremi] = useState(null);
    const [showVideo, setShowVideo] = useState(false);

    useEffect(() => {
      let currentPremis = RouterData.route.params.projecte.premis.filter(e => e.itinerari == RouterData.route.params.perfilImatges.itinerari);
      setPremis(currentPremis)
    },[]);

    const renderVistaItinerari = () => {
        let items = []
       switch(RouterData.route.params.perfilImatges.estat)
        {
            case "no visitat":
              premis.map((premi, index) => {
                if(!premi.superPremi){
                  items.push(
                      <View style={[style.premiContainer]} key={index}>
                        <TouchableOpacity >
                          <Image style={[style.premiImage,{opacity:0.2}]} source={{uri: api.api_url + premi.Premi.url}}/>
                        </TouchableOpacity>
                      </View>
                  )
                }
              })
              break;

            case "finalitzat":
              premis.map((premi, index) => {
                if(!premi.superPremi){
                  items.push(
                      <View style={[style.premiContainer]} key={index}>
                        <TouchableOpacity onPress={()=>{setShowPremi(premi),setModalVisible(true);}}>
                          <Image style={[style.premiImage]} source={{uri: api.api_url + premi.Premi.url}}/>
                        </TouchableOpacity>
                      </View>
                  )
                }
              })
              break;

            case "comencat":
              premis.map((premi, index) => {
                if(!premi.superPremi){
                  items.push(
                      <View style={[style.premiContainer]} key={index}>
                        <TouchableOpacity onPress={()=>{RouterData.route.params.perfilImatges.pois[index]?.done == true ? (setShowPremi(premi),setModalVisible(true)) : null}}>
                          <Image style={[style.premiImage, RouterData.route.params.perfilImatges.pois[index]?.done == true ? null : {opacity: 0.2}]} source={{uri: api.api_url + premi.Premi.url}}/>
                        </TouchableOpacity>
                      </View>
                  )
                }
              })
              break;
        }

        return items;
    }
    
    const showSuperPremi = () => {
        let premiUrl = premis.find(e => e.superPremi == true)?.Premi.url
        if(RouterData.route.params.perfilImatges.estat == "finalitzat" || RouterData.route.params.perfilImatges.showSuperPremi )
        {
          if(premiUrl){
            return(
                <View style={style.superPremiContainer}>
                    <Image style={style.image} source={{uri: api.api_url + premiUrl }}/>
                </View>
            )
          }else
          {
           return null
          }
        }else
        {
          if(premiUrl){
            return(
              <View style={style.superPremiContainer}>
                  <Image style={style.image} source={{uri: api.api_url + RouterData.route.params.recursos.find(e => e.tipus_recurs == 'superPremi')['imatge_recurs'].url }}/>
              </View>
            )
          }
        }
    }

    const showPremiAlternatiu = () => {
        if(RouterData.route.params.perfilImatges.estat == "finalitzat")
        {
          return(
            <TouchableOpacity style={{width:'80%',height:'50%'}} onPress={()=>{setShowVideo(true)}}>
              <Image style={[style.image]} source={{uri: api.api_url + RouterData.route.params.projecte.premi_alternatiuses.find(e => e.itinerari == RouterData.route.params.perfilImatges.itinerari)?.Imatge2.url }}/>
            </TouchableOpacity>
          )
        }else
        {
          return(
            <Image style={{width:'80%',height:'50%',resizeMode:'contain'}} source={{uri: api.api_url + RouterData.route.params.projecte.premi_alternatiuses.find(e => e.itinerari == RouterData.route.params.perfilImatges.itinerari)?.Imatge1.url }}/>
          )
        }
      }

    
  if(showVideo){ //=> Mostrar video de premi alternatiu ( esta guardat en local )
    return(
      <View id="videoContainer" style={style.videoContainer}>
          {/* <video id="video" onPause={ e => this.explanation() }  
              style={{
                  height: '100%',
                  width: '100vw',
                  resizeMode: 'contain',
                  objectFit: 'contain',
                  position: 'fixed',
                  left: 0,
                  top: 0,
                  zIndex: -1
              }}
          autoPlay>
              <source id="videoSource"  src={require("../../../assets/Videos/PemisItinerari/"+RouterData.route.params.perfilImatges.Nom+".mp4")} type="video/mp4" />
          </video> */}
          <TouchableOpacity style={globalstyle.crossBox} onPress={()=>{ setShowVideo(false)}}>
              <Image source={{uri: api.api_url + RouterData.route.params.recursos.find(e => e.tipus_recurs == 'pop_up_close_icon')['imatge_recurs'].url }} style={globalstyle.cross} />
          </TouchableOpacity>
      </View>
  )
  }else
  {
    return (
      <View style={{flex:1}}>
        {modalVisible ? PopUpPerfilImatge(ShowPremi,modalVisible,setModalVisible) : null}
        <ImageBackground source={ api.api_url + RouterData.route.params.recursos.find(e => e.tipus_recurs == 'background_default')['imatge_recurs'].url } style={{flex:1, resizeMode: "cover"}}>
          <View style={{flex:20,justifyContent:'center',alignItems:'center'}}>
            <View style={style.boxNom}>
              <Text style={style.nomIter}>{RouterData.route.params.perfilImatges.Nom}</Text>
            </View>
          </View>
          <View style={{flex:20,justifyContent:'center',alignItems:'center'}}>
            <View style={[style.boxNom,{width:'60%',height:'40%',borderWidth:'1px'}]}>
              <Text style={style.SecondTitle}>{RouterData.route.params.recursos.find(e => e.tipus_recurs === 'total_itinerari')['text_recurs' + RouterData.route.params.idioma]}</Text>
              <Text style={style.SecondText}>{RouterData.route.params.perfilImatges.estat != "no visitat" ? RouterData.route.params.perfilImatges.punts : 0} </Text>
             </View>
          </View>
          {/* <View style={{flex:20 , flexDirection:'row' }}>
            <View style={{flex:1, justifyContent:'center',alignItems:'center', width:'60%'}}>
              <Text style={style.SecondTitle}>{RouterData.route.params.recursos.find(e => e.tipus_recurs === 'total_itinerari')['text_recurs' + RouterData.route.params.idioma]}</Text>
              <Text style={style.SecondText}>{RouterData.route.params.perfilImatges.estat != "no visitat" ? RouterData.route.params.perfilImatges.punts : 0} </Text>
            </View>
            <View style={{flex:1, justifyContent:'center',alignItems:'center', width:'30%',paddingRight:'5%'}}>
              {showPremiAlternatiu()}
            </View>
          </View> */}
          {/* <View style={{flex:10}}>
            {showSuperPremi()}
          </View> */}
          <View style={{flex:50,alignContent:'center',alignSelf:'center'}}>
            <ScrollView style={{flex:1, maxHeight: "100%",width: Dimensions.get('screen').width * 0.7 ,}}>
              <View style={{flexWrap: "wrap", flexDirection: "row", width: "100%", alignContent: 'center', placeContent: "center", justifyContent: "center",justifyContent:'center'}}>
                {renderVistaItinerari()}
              </View>
            </ScrollView>
          </View>
          <View style={{flex:10}}>
            {Menu(RouterData)}
          </View>
        </ImageBackground>
      </View>
    );
  }
}

export default ImatgesPerfilItinerari;