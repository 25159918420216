
import { Feature } from "ol";
import { Point } from "ol/geom";
import { fromLonLat } from "ol/proj";
import { getDistance } from "ol/sphere";
import { RFeature, RLayerVector, RStyle } from "rlayers";
import api from "../../../Config/api_urls"
import FlashMessage,{showMessage,hideMessage} from "react-native-flash-message";
import PopUpMapaHistoria from "../PopUps/PopUpMapaHistoria";
import PopUpMapa from "../PopUps/PopUpMapa";

function PoisMapa(RouterData,features,location)
{
    const poiIconSize = 0.1
    const puntDisponible = api.api_url + RouterData.route.params.recursos.find(e => e.tipus_recurs == 'itinerari_punt_disponible')['imatge_recurs'].url;  
    const puntActual = api.api_url + RouterData.route.params.recursos.find(e => e.tipus_recurs == 'itinerari_punt_actual')['imatge_recurs'].url;
    const puntPrimer = api.api_url + RouterData.route.params.recursos.find(e => e.tipus_recurs == 'itinerari_punt_inici')['imatge_recurs'].url;
    const puntPrimerRealitzat = api.api_url + RouterData.route.params.recursos.find(e => e.tipus_recurs == 'itinerari_punt_inici_realitzat')['imatge_recurs'].url;
    const puntRealitzat = api.api_url + RouterData.route.params.recursos.find(e => e.tipus_recurs == 'itinerari_punt_realitzat')['imatge_recurs'].url;
    const puntFinal = api.api_url + RouterData.route.params.recursos.find(e => e.tipus_recurs == 'itinerari_punt_final')['imatge_recurs'].url;
    const puntFinalRealitzat = api.api_url + RouterData.route.params.recursos.find(e => e.tipus_recurs == 'itinerari_punt_final_realitzat')['imatge_recurs'].url;
    
    FlashMessage.setColorTheme({
        success: "#25c879",
        info: "#715f4d",
        warning: "#715f4d",
        danger: "#d990fb",
    });

    //todo 
    const _renderFeatures = (feature,icon,poi) => {
        // if(RouterData.route.params.currentItinerari.currentOrder == poi.order)
        // {
        //     getAngle([parseFloat(poi.location[0]),parseFloat(poi.location[1])])
        // }
        return (
            <RLayerVector>
                <RStyle.RStyle>
                    <RStyle.RIcon src={icon} scale={poiIconSize}/>
                </RStyle.RStyle>
                    <RFeature
                        key={feature.get('uid')}
                        feature={feature}
                        onClick={(e) => {
                            poi.done ? console.log("No hi ha event poi Passed") : poiEvents(poi,e);
                        }}
                    />
            </RLayerVector>
        )
    }
    // get angle between gyroscope and poi 
    // const getAngle = (poi) => {
    //     const angle = Math.atan2(poi[1] - location[1], poi[0] - location[0]) * 180 / Math.PI;
    // }

    const poiEvents = (poi,e) => {
        let distance = getDistance([parseFloat(poi.location[0]),parseFloat(poi.location[1])],location);
        if(RouterData.route.params.itinerari.pois.find(e => e.id == poi.id)?.distancia_activacio)
        {
            if(RouterData.route.params.currentItinerari.currentOrder != poi.order)
            {
                //todo posar missatge punt equivocat 
                showMessage({
                    message: RouterData.route.params.recursos.find(e => e.tipus_recurs == 'distancia_titol')['text_recurs'+RouterData.route.params.idioma],
                    description: RouterData.route.params.recursos.find(e => e.tipus_recurs == 'distancia_descripcio')['text_recurs'+RouterData.route.params.idioma],
                    type: "warning",
                    icon: {icon:"auto",position: "left"}
                }); 
                
            }else{ //=>HERE
                
                let distanciaActivacio = RouterData.route.params.itinerari.pois.find(e => e.id == poi.id).metresActivacio ? RouterData.route.params.itinerari.pois.find(e => e.id == poi.id).metresActivacio : 20;
                // let distanciaActivacio = 100000000;
                if(distance <= distanciaActivacio)
                {
                    RouterData.route.params.currentItinerari.comencat = true; //començar itinerari
                    e.map.getView().fit(e.target.getGeometry().getExtent(), {duration: 1000, maxZoom: 17}); // todo preguntar i millorar si es vol
                    setTimeout(() => {

                        switch(RouterData.route.params.itinerari.pois.find(e => e.id == poi.id)?.Tipus)
                        {
                            case "Joc360":
                                RouterData.navigation.navigate('CheckIn',{...RouterData.route.params,poi});
                                break;
                            case "Info":
                                RouterData.navigation.navigate('CheckIn',{...RouterData.route.params,poi});
                                break;
                            case "Checkin":
                                RouterData.navigation.navigate('CheckIn',{...RouterData.route.params,poi});
                                break;
                            case "Quizz":
                                RouterData.navigation.navigate('Quizz',{...RouterData.route.params,poi});
                                break;
                            case "QuizzImatges":
                                RouterData.navigation.navigate('Quizz',{...RouterData.route.params,poi});
                                break;
                            case "QuizzLabel":
                                RouterData.navigation.navigate('QuizzLabel',{...RouterData.route.params,poi});
                                break;
                            case "DragAndDrop":
                                RouterData.navigation.navigate('DragAndDrop',{...RouterData.route.params,poi});
                                break;
                            default:
                                console.warn("Error joc not found");
                                break;
                        }

                    }, 1000);
                }else
                {
                    showMessage({
                        message: RouterData.route.params.recursos.find(e => e.tipus_recurs == 'distancia_titol')['text_recurs'+RouterData.route.params.idioma],
                        description: RouterData.route.params.recursos.find(e => e.tipus_recurs == 'distancia_descripcio')['text_recurs'+RouterData.route.params.idioma],
                        type: "warning",
                        icon: {icon:"auto",position: "left"}
                    });
                }
            }
        }
    }


    let pois = []
    RouterData.route.params.currentItinerari.pois.forEach((element,i) => {
        if(element.isFirst)
        {
            if(element.done)
            {
               pois.push(_renderFeatures(features[i],puntPrimerRealitzat,element))
            }else
            {
                pois.push(_renderFeatures(features[i],puntPrimer,element))
            }
        } else if(element.isLast)
        {
            if(element.done)
            {
                pois.push( _renderFeatures(features[i],puntFinalRealitzat,element))
            }else
            {
                pois.push(_renderFeatures(features[i],puntFinal,element))
            }
        }else
        {
            if(element.done)
            {
                pois.push( _renderFeatures(features[i],puntRealitzat,element))
            }else
            {
                if(RouterData.route.params.currentItinerari.currentOrder == element.order)
                {
                    pois.push(_renderFeatures(features[i],puntActual,element))
                }else
                {
                    pois.push(_renderFeatures(features[i],puntDisponible,element))
                }
            }
        }

    });
    return pois
}

export default PoisMapa;

  //    e.map.getView().fit(e.target.getGeometry().getExtent(), {duration: 1000, maxZoom: 17});