import React, { useEffect, useState } from "react";
import { View,Image, ImageBackground, TouchableOpacity,Text } from "react-native";
import * as WebBrowser from 'expo-web-browser';

import style from "../../../Styles/main";
import globalstyle from "../../../Styles/Globals/globals";
import getIdioma  from "../../Functions/getIdioma";
import { getMainData, getRelatsData, getPoisData } from "../../Functions/getData";
import api  from "../../../Config/api_urls";
import constants from "../../../Config/constants";
import { createToken } from "../../Functions/createToken";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { getDeviceTypeAsync } from "expo-device";
import getDevice from "../../Functions/getDevice";
import * as Location from 'expo-location';
import getLocation from "../../Functions/getLocation";
import { setAnalyticsCollectionEnabled } from "expo-firebase-analytics";



function Main(RouterData) {
  
  const [idioma, setIdioma] = useState("");
  
  const [loadingMainData, setLoadingMainData] = useState(false);
  const [loadingPois, setLoadingPois] = useState(false);

  const [data, setData] = useState({ projecte : "",recursos : "", premisAlternatius : "", });
  const [itineraris, setItineraris] = useState(null);
  const [device, setDevice] = useState("");

  const _handlePressButtonAsync = async (link) => {
    let result = await WebBrowser.openBrowserAsync(link);
  };



  const navigate = () => {
    let itinerari = itineraris[0];
    // RouterData.navigation.navigate('PreviTrucada',{ projecte:data.projecte, recursos : data.recursos, premisAlternatius : data.premisAlternatius, itineraris,idioma})
    AsyncStorage.getItem("nom").then(nom => {
      if(!nom)
      {
        RouterData.navigation.navigate(
          'Login',{ projecte:data.projecte, recursos : data.recursos, premisAlternatius : data.premisAlternatius, itineraris, itinerari,idioma});
          //'VideoAbansLogin',{ projecte:data.projecte, recursos : data.recursos, premisAlternatius : data.premisAlternatius, itineraris,idioma})
      }else
      {
        AsyncStorage.getItem("itinerari"+itinerari.id).then((Asyncitinerari) => {
          if(Asyncitinerari){
            // RouterData.navigation.navigate("PreviTrucada",{projecte:data.projecte, recursos : data.recursos, premisAlternatius : data.premisAlternatius, itineraris, itinerari,idioma, currentItinerari : JSON.parse(Asyncitinerari),itinerari : itinerari});
              RouterData.navigation.navigate("Mapa",{projecte:data.projecte, recursos : data.recursos, premisAlternatius : data.premisAlternatius, itineraris, itinerari,idioma, currentItinerari : JSON.parse(Asyncitinerari),itinerari : itinerari});
          }else{
              RouterData.navigation.navigate("InfoItinerari",{projecte:data.projecte, recursos : data.recursos, premisAlternatius : data.premisAlternatius, itineraris, itinerari,idioma, itinerari : itinerari});
          }
      })
      }
      })
  }
 
  useEffect(() => {
    /* Device language and device type */
    setAnalyticsCollectionEnabled(true)
    setIdioma(getIdioma());
    getDevice().then(device => {
      setDevice(device);
    }).catch(err => {
      console.log(err);
    })
    /*Create token */
    AsyncStorage.getItem('user_token').then(token => {
      if(!token)
      {
        AsyncStorage.setItem('user_token', createToken(35));
      }
    });

    /* Get data from API */
    getMainData().then((response) => {
      setData({ projecte : response[0], recursos : response[0].recursos, premisAlternatius : response[0].premi_alternatiuses});
      setTimeout(() => {
        setLoadingMainData(true);
      }, 1000);
    }, (error) => {
      console.log(error);
    });

    getPoisData().then((response) => {
      setItineraris(response)
      setTimeout(() => {
        setLoadingPois(true);
      }, 1000);
    }, (error) => {
      console.log(error);
    });

    getLocation()
   
  }, []);
  if (!loadingMainData && !loadingPois) {
    return (
      <View style={style.mainView}>
        <Image source={require("../../../assets/Images/loading.gif")} style={style.loading} />
      </View>
    );
  }else
  {
    if(device != "phone" && device != "tablet" && device != "unknown")
    {
      return(
        <ImageBackground
        source={{uri: api.api_url + data.recursos.find(e => e.tipus_recurs == '01_background_qr')['imatge_recurs' + idioma]?.url}}
        style={globalstyle.backgroundImatges}>
      </ImageBackground>
      )
    }else{
      return (
        <View style={{flex: 1}}>
          <TouchableOpacity style={{flex: 1}} onPress={() => { 
          navigate();
          }}>
            <ImageBackground
              source={{uri: api.api_url + data.recursos.find(e => e.tipus_recurs == '01_background_inici')['imatge_recurs' + idioma]?.url}}
              style={globalstyle.backgroundImatges}>
            </ImageBackground>
          </TouchableOpacity>
          
          <View style={style.ViewPerMesInfo}>
            <TouchableOpacity style={{flex: 1,alignSelf: "right"}}
              onPress={() => { _handlePressButtonAsync(constants.hrefProjecteUrl) }}>
              <Image
                resizeMode="contain"
                style={style.perMesInfo}
                source={{uri: api.api_url + data.recursos.find(e => e.tipus_recurs == 'buto_info_pagina_principal')['imatge_recurs' + idioma]?.url}}/>
            </TouchableOpacity>
          </View>

        </View>
      );
    }
  }
}

export default Main;