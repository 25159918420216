import AsyncStorage from "@react-native-async-storage/async-storage";
import React, { useEffect, useState } from "react";
import {View,Text,ImageBackground,ScrollView,Image,TouchableOpacity,Modal, Dimensions} from "react-native";
import api from "../../../Config/api_urls";

import globalstyle from "../../../Styles/Globals/globals";
import Menu from '../Menu/Menu';
import style from "../../../Styles/premi"
import ReactMarkdown from "react-markdown";

function Premi(RouterData)
{
    let poiData = RouterData.route.params.itinerari.pois.find(e => e.id == RouterData.route.params.poi.id);
    let currentPremi = RouterData.route.params.projecte.premis.find(e => e.id == poiData.premi);
    useEffect(()=>{
        if(RouterData.route.params.currentItinerari.pois.find(e => e.id == RouterData.route.params.poi.id).isLast == true)
        {
            RouterData.route.params.currentItinerari.finalitzat = true;
        }
        RouterData.route.params.currentItinerari.pois.find(e => e.id == RouterData.route.params.poi.id).done = true;
        RouterData.route.params.currentItinerari.puntsTotals = parseInt(RouterData.route.params.currentItinerari.puntsTotals) + parseInt(RouterData.route.params.currentItinerari.pois.find(e => e.id == RouterData.route.params.poi.id).punts);
        RouterData.route.params.currentItinerari.currentOrder = RouterData.route.params.currentItinerari.currentOrder + 1;
        
        AsyncStorage.setItem('itinerari'+RouterData.route.params.itinerari.id,JSON.stringify(RouterData.route.params.currentItinerari));
        AsyncStorage.getItem('puntsTotals').then(punts => {
            if(punts == null)
            {
                AsyncStorage.setItem('puntsTotals',RouterData.route.params.currentItinerari.puntsTotals.toString());
            }else
            {
                AsyncStorage.setItem('puntsTotals',(parseInt(punts) + parseInt(RouterData.route.params.currentItinerari.pois.find(e => e.id == RouterData.route.params.poi.id).punts)).toString());
            }
        });

    },[])

    const saltarTrucada = () => {
        RouterData.navigation.navigate('PreviTrucada',RouterData.route.params);
    }

    {/* Es fa servir amb projectes sense cenobify (per google analytics) */}
    const sendToAnalitycs = async() => {
        Analytics.logEvent("Posta_"+this.state.currentStateItinerari.currentOrder,{
            screen : "Posta_"+this.state.currentStateItinerari.currentOrder,
            purpose : 'Ha acabat el premi '+ this.state.currentStateItinerari.currentOrder,
        })
    }

    return(
        <View style={{flex:1,width:'100%',height:'100%',maxHeight:Dimensions.get('screen').height,maxWidth:'100%'}}>
            <ImageBackground source={{uri: api.api_url + RouterData.route.params.recursos.find(e => e.tipus_recurs == 'background_default').imatge_recurs.url}} style={{flex:1}}>
                
                {/* Bocata has guanyat/puntuacio */}
                <View style={{flex:20}}>
                    <ImageBackground source={{uri: api.api_url + RouterData.route.params.recursos.find(e => e.tipus_recurs == 'bocata_premi').imatge_recurs.url}} style={{flex:1,width:'100%',alignItems:'center',alignSelf:'center',justifyContent:'center',height:'100%'}} resizeMode={'contain'}>
                        <View style={{alignItems:'center',alignSelf:'center',justifyContent:'center',paddingTop:6}}>
                            <Text style={style.textPremi}>{RouterData.route.params.recursos.find(e => e.tipus_recurs == 'guanyar')['text_recurs'+RouterData.route.params.idioma]}</Text>
                            <Text style={style.punts}>{RouterData.route.params.currentItinerari.pois.find(e => e.id == RouterData.route.params.poi.id).punts}/{currentPremi.punts}</Text>
                            <Text  style={style.textPremi}>{RouterData.route.params.recursos.find(e => e.tipus_recurs == "total_itinerari")['text_recurs'+ RouterData.route.params.idioma]}</Text>
                            <Text style={style.punts} >{parseInt(RouterData.route.params.currentItinerari.puntsTotals) + parseInt(RouterData.route.params.currentItinerari.pois.find(e => e.id == RouterData.route.params.poi.id).punts)}/{RouterData.route.params.currentItinerari.puntsItinerari}</Text>
                        </View>
                    </ImageBackground>
                </View>
                
                {/* Image iframe */}
                <View style={{flex:2.5}}/>
                <View style={{flex:35,justifyContent:'center',width:'80%',height:'70%',alignSelf:'center'}}>
                        {
                            !currentPremi['esUnVideo'] ?
                            (
                                <Image style={{width: '100%',height: '100%',alignSelf:'center',resizeMode:'contain'}} source={{uri: api.api_url + currentPremi.Premi.url}}/>
                            )
                            :
                            (
                                <iframe src={currentPremi['linkVideo']} allow="fullscreen;geolocation;camera;gyroscope;accelerometer;magnetometer" style={{height: "100%", width: "100%", resizeMode: 'contain',borderRadius:5}} />
                            )
                        }
                </View>
                {/* Text */}
                <View style={{flex:2.5}}/>
                <View style={[{flex:20,alignItems:'center'}]}>
                    <ScrollView  style={{flexGrow: 1, width:'90%',height:'100%'}}>
                        <View style={[style.descriptionContainer,{flexGrow: 1, width:'100%',height:'100%'}]}>
                            <ReactMarkdown>{currentPremi["descripcioPremiDetallat" + RouterData.route.params.idioma] ?? currentPremi["descripcioPremiDetallat" + RouterData.route.params.idioma] }</ReactMarkdown>
                        </View>
                    </ScrollView>
                </View>

                {/* Boto continuar */}
                <View style={{flex:10,justifyContent:'center'}}>
                    <TouchableOpacity 
                        onPress={()=>{
                            if(RouterData.route.params.currentItinerari.pois.find(e => e.id == RouterData.route.params.poi.id).isLast == true)
                            {
                                saltarTrucada();
                            }else
                            {
                                RouterData.navigation.navigate('Mapa',RouterData.route.params);
                            }
                        }}
                    >
                        <Text style={globalstyle.botoContinuar}>{RouterData.route.params.recursos.find(e => e.tipus_recurs == "text_continuar")['text_recurs'+RouterData.route.params.idioma]}</Text>
                    </TouchableOpacity>
                </View>

                {/* Menu */}
                <View style={{flex:10}}>
                    {Menu(RouterData)}
                </View>
            </ImageBackground>
        </View>     
    )
}

export default Premi;