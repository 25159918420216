import AsyncStorage from "@react-native-async-storage/async-storage";

// => todo mirar idioma pel nom 


async function getPointsItinerari(DataItineraris) {
    let items = []
    let itinerarisFinalitzat = 0;

    await DataItineraris.map(itinerari => {
        AsyncStorage.getItem("itinerari" + itinerari.id).then( value => {
            if(value)
            {
                value = JSON.parse(value);
                if(value.finalitzat)
                {
                    items.push({itinerari: itinerari.id , estat : 'finalitzat', punts: value.puntsTotals , Nom : itinerari.Nom, showSuperPremi : true});
                    itinerarisFinalitzat = 1 + itinerarisFinalitzat ;
                }else
                {
                    items.push({itinerari: itinerari.id , estat : 'comencat', punts: value.puntsTotals , pois : value.pois , Nom : itinerari.Nom , showSuperPremi : true }); //showSuperPremi : true
                }
            }else
            {
                items.push({itinerari : itinerari.id , estat : 'no visitat' , Nom : itinerari.Nom})
            }
        })
    });
    
    return { itineraris: items , itinerarisFinalitzat: itinerarisFinalitzat };
}

export default getPointsItinerari;