import { StyleSheet } from "react-native";
import constants from "../Config/constants";

/* Styles for all PopUps */

const style = StyleSheet.create({
  fontFamily: "Open Sans",

  caixaPopUp: {
    display: "flex",
    height: "60%",
    width: "80%",
    borderColor: constants.colorCorporatiu,
    borderStyle: "solid",
    borderWidth: 4,
    borderRadius: 5,
  },
  modalOutBackground: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  backgroundImatges: {
    flex: 1,
    resizeMode: "cover",
    justifyContent: "center",
    paddingBottom: 15,
    alignItems: "center",
  },

  cross: {
    height: 30,
    width: 30,
  },
  bafarada: {
    resizeMode: "contain",
    width: "100%",
    height: "20%",
  },
  TouchableSatisfaccio: {
    width: "100%",
    height: "20%",
  },
  ImgSatisfaccio: {
    resizeMode: "contain",
    width: "100%",
    height: "90%",
  },

  popUpTitleInicial: {
      fontSize: "2.7vh",
      color: "#009499",
      textAlign: "center",
      justifyContent: "center",
      fontWeight: "bold",
      alignItems: "center",
      fontFamily: "Gothic",
      textShadow: "-0.5vh -0.5vh 0.5vh #f2eed7, 0.5vh -0.5vh 0.5vh #f2eed7, -0.5vh 0.5vh 0.5vh #f2eed7, 0.5vh 0.5vh 0.5vh #f2eed7"
  },

  popUpMapaText: {
    color: "#6360a7",
    fontSize: "2.5vh",
      color: "#fff",
      textAlign: "center",
      // justifyContent: "center",
      // alignItems: "center",
      fontFamily: "Gothic",
      // textShadow: "-0.5vh -0.5vh 0.5vh #f2eed7, 0.5vh -0.5vh 0.5vh #f2eed7, -0.5vh 0.5vh 0.5vh #f2eed7, 0.5vh 0.5vh 0.5vh #f2eed7",
    width: "90%",
    alignSelf: "center",
  },

  descriptionContainerHistoria: {
    flex:40,
    width: "80%",
    textAlign: "center",
    alignSelf: 'center',
    fontFamily: 'Gothic',
    fontSize: "2vh",
    // color: "#3d342a"
},
  imageFlag: {
    resizeMode: "contain",
    alignSelf: "center",
    justifyContent: "center",
    width: "50%",
    height: "50%",
    marginTop: 15,
    marginBottom: 15,
  },
  xarxesContainer: {
    flex: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor:'black',
    width: "100%",
    height: "100%",
  },

  popUpMapaImatge: {
    width: "80%",
    height: "100%",
    resizeMode: "contain",
    alignSelf: "center",
  },
  imatgePremiPrefil: {
    borderColor: constants.colorCorporatiu,
    width: "100%",
    height: "100%",
    resizeMode: "contain",
  },
  textPerfil : 
  {
    color: "white",
    fontSize: "2.2vh",
    fontWeight: "bold",
    textAlign: "center",
  }
});

export default style;
