import React from "react";
import { View, Text,Modal,ImageBackground,TouchableOpacity,Image} from "react-native";

import api from "../../../Config/api_urls";
import globalstyle from "../../../Styles/Globals/globals";
import style from "../../../Styles/popUp";

function PopUpQuizzLabel(RouterData,pista,setPista,infoPista,numPista,setNumPista) {
    if(!infoPista)
    {
        infoPista ={ id : 3 , valid: RouterData.route.params.recursos.find(e => e.tipus_recurs == "text_resposta_correcta")["text_recurs"+RouterData.route.params.idioma] + " " +  RouterData.route.params.itinerari.pois.find(e => e.id == RouterData.route.params.poi.id)['Quizz'+RouterData.route.params.idioma].respostes[0].valid}
    }
    return (
    <Modal animationType="fade" transparent={true} visible={pista}>
       <View style={style.modalOutBackground}>
                <View style={[style.caixaPopUp,{height:"50%"}]}>
                    <ImageBackground source={{uri: api.api_url + RouterData.route.params.recursos.find(e => e.tipus_recurs == 'background_default')['imatge_recurs'].url}} style={style.backgroundImatges}>
                        {/* Close button */}
                        <TouchableOpacity style={globalstyle.crossBox} onPress={()=>{setPista(false);setNumPista(numPista+1)}}>
                            <Image source={{uri: api.api_url + RouterData.route.params.recursos.find(e => e.tipus_recurs == 'pop_up_close_icon')['imatge_recurs'].url }} style={style.cross} />
                        </TouchableOpacity>
                    <View style={{flex:30,justifyContent:'center',alignItems:'center',alignSelf:'center'}}>
                        <Text style={style.popUpTitleInicial}>{RouterData.route.params.recursos.find(e=> e.tipus_recurs == "text_pista")["text_recurs"+RouterData.route.params.idioma]} {infoPista?.id}</Text>
                    </View>
                    <View style={{flex:50,justifyContent: 'center', alignItems: 'center'}}>
                        <Text style={style.popUpMapaText}> {infoPista.valid} </Text>
                    </View>
                    <View style={{flex:20,justifyContent:'center',alignItems:'center',alignSelf:'center'}}>
                            <TouchableOpacity onPress={()=>{setPista(false);setNumPista(numPista+1)}}>
                                <Text style={globalstyle.botoContinuar}>{RouterData.route.params.recursos.find(e => e.tipus_recurs == 'text_continuar')['text_recurs'+ RouterData.route.params.idioma]}</Text>
                            </TouchableOpacity>
                    </View>
                </ImageBackground>
            </View>
        </View>
    </Modal>
  )
}

export default PopUpQuizzLabel;