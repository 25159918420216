import { StyleSheet } from 'react-native';
import { Dimensions } from 'react-native';

import constants, { colorCorporatiu , colorBotons,  } from '../Config/constants'

/* Styles for the component Ininerari and InfoItinerari */

const style = StyleSheet.create({
    
    backgroundImatges: {
        height: "100%",
        resizeMode: "cover",
        justifyContent: "center",
    },
    touchableIcon:{
        flex: 1,
        width:"50%",
        height:"100%",
        alignSelf:'center'
      },
    menuIcon:
    {
        width:'55%',
        height: "90%",
        alignSelf:'center',
        resizeMode:'contain',
    },
    textItinerari:
    {
        alignSelf:'center',
        fontSize:'2vh',
        fontWeight:'bold',
        color: constants.colorCorporatiu,
        marginTop:'-4%',
    },
    titol : {
        fontSize: "4vh",
        color: "#009499",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "Leckerli One",
        textShadow: "-0.5vh -0.5vh 0.5vh #f2eed7, 0.5vh -0.5vh 0.5vh #f2eed7, -0.5vh 0.5vh 0.5vh #f2eed7, 0.5vh 0.5vh 0.5vh #f2eed7"
    },
    descriptionContainer: {
        flex:40,
        width: "80%",
        textAlign: "center",
        alignSelf: 'center',
        fontFamily: 'OpenSans',
        fontSize: "2.5vh",
        // color: "#3d342a"
    },
    imageItinerari: {
      width:'95%',
      height:'90%',
      alignSelf:'center',
      resizeMode:'contain'
    },
    subtitol: {
        fontSize: 20,
        textAlign: "center",
        fontWeight: "500",
        width: '100%',
        alignSelf: 'center',
        color: constants.colorCorporatiu,
        paddingBottom: 4,
    },
 
});
    
export default style; 