//var api_url = 'http://iternaturastrapi.ferranmaso.com';
//var api_url = 'https://iternaturastrapi.cenobify.com'
//var token = 'hejoi32nyrt8rn32ued8fnsy78nr3ui2o1lkdkkajfidsa';
//var api_url = "https://strapi.iternatura.es";
var api_url = "https://strapi.iternatura.es";
var api_cenobify = "https://apicenobify.iternatura.es"

var tokenSallent = 'GrdYaMpPTGtSxucv129aS8PtTfbcIFWR';

export default {
    api_url: api_url,
    api_cenobify: api_cenobify,
    postEstadistiques: api_cenobify + '/datos/new',
    postRelats: api_cenobify + '/relat/new',
    getRelats: api_cenobify + '/relat/getPublicats',
    postSatisfaccio: api_cenobify + '/satisfaccion/new',
    premis: api_url + '/premis',
    basMagia: api_url + '/projectes?token=' + tokenSallent,
    basMagia_poi: api_url + '/itineraris?id=',
    basMagia_poi_all: api_url + '/itineraris?projecte.id='+8,
    basMagia_premis: api_url + '/premis?projecte=',
};