import { Dimensions, StyleSheet } from 'react-native';
import constants from '../Config/constants';

/* Styles for the component Home */

const style = StyleSheet.create({
    fontFamily: 'Open Sans',

    backgroundImatges : {
        flex: 1,
        resizeMode: "cover",
        justifyContent: "center",
    },
    safeAreaAnswer: {
        width: "80%",
        justifyContent: 'center',
        alignItems: 'center',
    },
    solucio:{
        height:'50%',
        width:'50%'
    },

    cardContainer: {
        height: Dimensions.get('screen').height * 35 /250,
        width: Dimensions.get('screen').height * 49 /250,
        margin: 4,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius:5
    },
    answer: {
        fontSize: '1rem',
        textAlign: "center",
        fontWeight: "bold",
        width:'95%',
        fontFamily:'Gothic'
    },
    answerBack: {
        color: "#a88f40",
        fontWeight: "bold",
        fontSize: 15
    },
    question: {
        color: '#403d27',
        fontSize: "2vh",
        fontWeight: "bold",
        textAlign: "center",
        width: "70%",
        fontFamily:'Gothic'
    },
    questionBackground: {
        width:'80%',
        height: "80%",
        justifyContent: "center",
        backgroundColor: "white",
        borderRadius: 15,
        paddingVertical: 7,
    },
    scroll : {
        justifyContent:'center',
        flexGrow:1
    }, 
    
    containerAnswer: {
        backgroundColor: 'white',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        height: "100%",
        width: "100%",
        borderRadius:5
    },
    questionImage: {
        height: "90%",
        width: "90%"
    },
    containerAnswerBack: {
        backgroundColor: 'white',
        justifyContent: 'center',
        alignItems: 'center',
        height: "100%",
        width: "100%",
        borderRadius:5
    },

    answerInput : {
        width: "100%",
        height:'60%',
        borderColor: "#878c90",
        borderWidth: 8,
        borderStyle: "solid",
        backgroundColor: "white",
        padding: 10,
        fontWeight: 500,
        fontSize: "2.2vh",

    },
    encert : {
        height: "70%",
        width: "70%",
    }
  
});
    
export default style; 