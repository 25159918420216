import React,{useState} from "react";
import {View,Text,ImageBackground,ScrollView,Image,TouchableOpacity,Modal} from "react-native";


import style from "../../../Styles/popUp";
import api from "../../../Config/api_urls";
import globalstyle from "../../../Styles/Globals/globals";
import constants from "../../../Config/constants";

function PopUpMapa(RouterData,poi,modalHistoria,setModalHistoria){

    const jocNavigate = () => { //todo ajuntar amb la funcio de pois
        setModalHistoria(false);
        RouterData.route.params.currentItinerari.pois.find(e => e.id == poi.id).popUpLoaded = true;
        RouterData.route.params.currentItinerari.comencat = true;
        switch(poi.Tipus)
        {
            case "Joc360":
                RouterData.navigation.navigate('CheckIn',{...RouterData.route.params,poi});
                break;
            case "Info":
                RouterData.navigation.navigate('CheckIn',{...RouterData.route.params,poi});
                break;
            case "Checkin":
                RouterData.navigation.navigate('CheckIn',{...RouterData.route.params,poi});
                break;
            case "Quizz":
                RouterData.navigation.navigate('Quizz',{...RouterData.route.params,poi});
                break;
            case "QuizzImatges":
                RouterData.navigation.navigate('Quizz',{...RouterData.route.params,poi});
                break;
            case "QuizzLabel":
                RouterData.navigation.navigate('QuizzLabel',{...RouterData.route.params,poi});
                break;
            case "DragAndDrop":
                RouterData.navigation.navigate('DragAndDrop',{...RouterData.route.params,poi});
                break;
            default:
                console.warn("Error joc not found");
                break;
        }
    }

    return(
        <Modal animationType="fade" transparent={true} visible={modalHistoria}>
            {console.log(poi)}
            <View style={style.modalOutBackground}>
                <View style={[style.caixaPopUp,{height:"50%"}]}>
                    <ImageBackground source={{uri: api.api_url + RouterData.route.params.recursos.find(e => e.tipus_recurs == 'background_default')['imatge_recurs'].url}} style={style.backgroundImatges}>
                        
                        {/* Close button */}
                        <TouchableOpacity style={globalstyle.crossBox} onPress={()=>{setModalHistoria(false)}}>
                            <Image source={{uri: api.api_url + RouterData.route.params.recursos.find(e => e.tipus_recurs == 'pop_up_close_icon')['imatge_recurs'].url }} style={style.cross} />
                        </TouchableOpacity>
                        
                        {/* Header */}
                        <View style={{flex:40,alignItems:'center',width:'85%',justifyContent:'center'}}>
                            <Text style={[style.popUpTitleInicial   ]}> {poi['Nom'+RouterData.route.params.idioma]}</Text>
                        </View>

                        {/* Body */}
                        <View style={{flex:40,alignItems:'center',alignSelf:'center'}}>
                            <ScrollView style={{alignSelf:'center',}}>
                                <Text style={[style.popUpMapaText]}>{RouterData.route.params.recursos.find(e => e.tipus_recurs == 'popup_mapa_itinerari_2')['text_recurs'+ RouterData.route.params.idioma]}</Text>
                            </ScrollView>
                        </View>

                        {/* Footer */}
                        <View style={{flex:20,alignItems:'center',alignSelf:'center',justifyContent:'center'}}>
                            <TouchableOpacity onPress={()=>{jocNavigate()}}>
                                <Text style={globalstyle.botoContinuar}>{RouterData.route.params.recursos.find(e => e.tipus_recurs == 'text_continuar')['text_recurs'+ RouterData.route.params.idioma]}</Text>
                            </TouchableOpacity>
                        </View>

                    </ImageBackground>
                </View>
            </View>
        </Modal>
    )

}

export default PopUpMapa;