import React from 'react'
import {createStackNavigator} from '@react-navigation/stack'
import {NavigationContainer} from '@react-navigation/native'
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs'

{/* RUTES */}
import Main from './Components/Screens/Main/Main';
import Login from './Components/Screens/Login/Login';
import Presentacio from './Components/Screens/Presentacio/TextPrincipal';
import Home from './Components/Screens/Home/home';
import Itineraris from './Components/Screens/MenuItineraris/Itineraris';
import Mapa from './Components/Screens/Mapa/mapa';
import InfoItinerari from './Components/Screens/InfoItinerari/InfoItinerari';
import Quizz from './Components/Screens/Jocs/Quizz/Quizz';
import Premi from './Components/Screens/Premi/Premi';
import QuizzLabel from './Components/Screens/Jocs/Quizz/QuizzLabel';
import Checkin from './Components/Screens/Jocs/CheckIn/Checkin';
import Perfil from './Components/Screens/Perfil/Perfil';
import ImatgesPerfilItinerari from './Components/Screens/Perfil/ImatgesItinerari';
import VistaVR from './Components/Screens/360/VistaVR';
import Relats from './Components/Screens/Relats/Relats';
import * as Font from 'expo-font'
import Trucada from './Components/Screens/Trucada/Trucada';
import PreviaTrucada from './Components/Screens/Trucada/PreviTrucada';
import Credits from './Components/Screens/Credits/Credits';

const Tab = createBottomTabNavigator();
const Stack = createStackNavigator();

const App = () => (

    Font.loadAsync({
        "Leckerli One": require("./assets/Fonts/LeckerliOne-Regular.ttf"),
    }),
    Font.loadAsync({
        "Gothic" : require("./assets/Fonts/GOTHIC.TTF"),
    }),
    <NavigationContainer>
        <Stack.Navigator screenOptions={{ headerShown: false}}>
            <Stack.Screen name="Main"  component={Main}/>
            <Stack.Screen name="Presentacio" component={Presentacio}/>
            <Stack.Screen name='Login' component={Login} />
            <Stack.Screen name='Home' component={Home} />
            <Stack.Screen name='Itineraris' component={Itineraris} />
            <Stack.Screen name='Mapa' component={Mapa} />
            <Stack.Screen name='InfoItinerari' component={InfoItinerari} />
            <Stack.Screen name='Perfil' component={Perfil} />
            <Stack.Screen name="ImatgesPerfilItinerari" component={ImatgesPerfilItinerari} />
            <Stack.Screen name='VistaVR' component={VistaVR} />
            <Stack.Screen name='Relats' component={Relats} />
            <Stack.Screen name="Trucada" component={Trucada} />
            <Stack.Screen name="PreviTrucada" component={PreviaTrucada} />
            <Stack.Screen name="Credits" component={Credits} />

            {/* Jocs i premi */}
            <Stack.Screen name='Premi' component={Premi} />
            <Stack.Screen name='Quizz' component={Quizz} />
            <Stack.Screen name='QuizzLabel' component={QuizzLabel} />
            <Stack.Screen name='CheckIn' component={Checkin} />
            
        </Stack.Navigator>
    </NavigationContainer> 
)

export default App   