import React from "react";
import { View, Text, ImageBackground, Image, ScrollView,Dimensions } from 'react-native'
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

import style from '../../../Styles/credits';
import Menu from '../Menu/menu';
import api from "../../../Config/api_urls";

function Credits(RouterData)
{
    return (
        <View style={{ flex: 1 }}>
                <ImageBackground source={{uri: api.api_url + RouterData.route.params.recursos.find(e => e.tipus_recurs === 'background_default').imatge_recurs.url}} style={style.imageBackground}>
                    <View style={{flex:20,width:'100%',height:'100%'}}>
                        <Image style={{height: "100%", width: Dimensions.get('window').width, resizeMode: "contain",alignSelf:'center'}} source={{uri: api.api_url + RouterData.route.params.recursos.find(e => e.tipus_recurs === 'contacte_credits')['imatge_recurs'+RouterData.route.params.idioma].url}}/>
                    </View>
                        <ScrollView style={{height: '90%',flex:70}}>
                            <View style={style.text}>
                                <ReactMarkdown style={style.text}>{RouterData.route.params.projecte['Credits'+RouterData.route.params.idioma]}</ReactMarkdown>
                            </View>
                        </ScrollView>
                    <View style={{flex:10}}>
                    {Menu(RouterData)}
                </View>
                </ImageBackground>
        </View>
    )
}


export default Credits;