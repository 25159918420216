import { StyleSheet } from 'react-native';
import constants from '../../Config/constants';
/* Styles for the whole app */

const globalstyle = StyleSheet.create({

    backgroundImatges: {
        flex: 1,
        resizeMode: "cover",
        justifyContent: "center"
    },
    boto : {
        fontFamily: 'Gothic', //=>
        fontSize: "2.5vh",
        padding: 5,
        paddingHorizontal:10,
        alignSelf: 'center',
        backgroundColor: constants.colorBotons,
        color:'white',
        fontWeight: 'bold'
    },
    containerRow: {
        flex:1,
        flexDirection:'row',
        //paddingVertical:10,
    },

    botoContinuar : {
        fontFamily: 'Gothic',
        fontSize: 20,
        paddingTop: 6,
        paddingBottom: 6,
        paddingLeft: 25,
        paddingRight: 25,
        paddingHorizontal: 10,
        alignSelf: 'center',
        backgroundColor: constants.colorBotons,
        color: '#004955',
        fontWeight: 'bold',
        borderRadius: '40px',
        border: "none",

    },
    crossBox: {
        position: 'absolute',
        right: 6,
        top: 6
      },
      image: {
        width: "100%",
        height: "100%",
        resizeMode: "contain",
      },
      cross: {
        height: 30,
        width: 30
      }

})

export default globalstyle;