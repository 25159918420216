
import React, { useEffect, useState } from "react";
import { View, Text, StyleSheet, Image, TouchableOpacity, Dimensions } from "react-native";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

import globalstyle from "../../../Styles/Globals/globals";
import style from "../../../Styles/relats";
import api from "../../../Config/api_urls";
import Menu from "../Menu/Menu";
import { ImageBackground, ScrollView } from "react-native-web";
import AsyncStorage from "@react-native-async-storage/async-storage";



function Relats(RouterData) {

    return (
        <View style={{flex:1,width:'100%',height:'100%',maxHeight: Dimensions.get('window').height ,maxWidth:'100%'}}>
            <ImageBackground source={{uri: api.api_url + RouterData.route.params.recursos.find(e => e.tipus_recurs == '05_background_relats')['imatge_recurs'+RouterData.route.params.idioma].url}} style={{flex:1}}>
                {/* Header */}
                <View style={{flex:10}}><Text> </Text></View>

                {/* Body */}
                <View style={{flex:70,alignItems:'center',justifyContent:'center',width:'100%',height:'80%'}}>
                  
                    <View style={{flexGrow: 1, width:'100%',height:'100%'}}>
                   <View style={{width:'100%',height:'40%'}}>
                        <Image style={{ width:'100%',height:'100%',resizeMode:'cover' }} source={{ uri: api.api_url+ RouterData.route.params.projecte['ImatgePrincipalTextExplicatiu'].url }} />
                   </View>
                   <ScrollView style={{width: "100%",height:'100%',flexGrow: 1,}}>
                    <View style={style.text}>
                        <ReactMarkdown style={[style.text]}>{RouterData.route.params.projecte['TextExplicatiu'+ RouterData.route.params.idioma]}</ReactMarkdown>
                    </View>
                    </ScrollView>
                </View>
                   
                </View>

                {/* Footer */}
                <View style={{flex:10, justifyContent: 'center',alignItems:'center',width:'100%',height:'100%',flexDirection:'row'}}>
                </View>

                {/* Menu */}
                <View style={{flex:10}}>
                    {Menu(RouterData)}
                </View>

            </ImageBackground>
        </View>
    );
}

export default Relats;