import AsyncStorage from "@react-native-async-storage/async-storage";
import react, { useEffect, useState } from "react";
import { View,Text, ImageBackground, Image, TouchableOpacity, Dimensions } from "react-native";
import renderPopUp from "../PopUps/popUpSatisfaccio";

import style from "../../../Styles/itineraris";
import api from "../../../Config/api_urls";
import Menu from "../Menu/menu";

function Itineraris(RouterData){

    const [itinierari, setItinerari] = useState("");
    const [token, setToken] = useState("");

    const Itinerari = (itinerari) => {
        return(
            <TouchableOpacity style={style.touchableIcon} onPress={()=>{ navigation(itinerari) }} >
                <Image style={style.menuIcon} source={{uri: api.api_url + itinerari.Imatge.url}} />
                {/* <Text style={style.textItinerari}>{itinerari.Nom}</Text> */}
            </TouchableOpacity>
        )
    }

    const navigation = (itinerari) => {
        AsyncStorage.getItem("itinerari"+itinerari.id).then((Asyncitinerari) => {
            if(Asyncitinerari){
                RouterData.navigation.navigate("Mapa",{...RouterData.route.params, currentItinerari : JSON.parse(Asyncitinerari),itinerari : itinerari});
            }else{
                RouterData.navigation.navigate("InfoItinerari",{...RouterData.route.params, itinerari : itinerari});
            }
        })
    }

    useEffect(() => {
        AsyncStorage.getItem("finalitzacio_itinerari").then(itinerari => {
            if(itinerari)
            {
                setItinerari(itinerari);
            }
        });
        AsyncStorage.getItem("token").then(token => {
            if(token)
            {
                setToken(token);
            }
        });
    },[]);

  
    return(
        <View style={{flex:1}}>
            {/* PopUp itinerari */}
            {itinierari != "" ? renderPopUp(RouterData) : null}
            
            <ImageBackground source={{uri: api.api_url + RouterData.route.params.recursos.find(e => e.tipus_recurs == '05_background_home')['imatge_recurs'+RouterData.route.params.idioma].url}} style={style.backgroundImatges}>
                {/* Header */}
                <View style={{flex:30}}/>
                {/* Body */}
                <View style={{flex:60,}}>
                    <View style={{flex: 12}}>
                        {Itinerari(RouterData.route.params.itineraris.find(e => e.Nom ==  "Les goges del Fluvià"))}
                        {/* {Itinerari(RouterData.route.params.itineraris.find(e => e.Nom ==  "Proves"))} */} 
                    </View>
                    <View style={{flex: 12,flexDirection:'row'}}>
                        {Itinerari(RouterData.route.params.itineraris.find(e => e.Nom ==  "El burro savi"))}
                        {Itinerari(RouterData.route.params.itineraris.find(e => e.Nom ==  "Els paisatges de l'avi Berga"))} 
                    </View>
                    <View style={{flex: 12}}>
                        {Itinerari(RouterData.route.params.itineraris.find(e => e.Nom ==  "La sargantana Magdalena"))}
                    </View>
                    <View style={{flex: 12,flexDirection:'row'}}>
                        {Itinerari(RouterData.route.params.itineraris.find(e => e.Nom ==  "La bruixa del Mallol"))} 
                        {Itinerari(RouterData.route.params.itineraris.find(e => e.Nom ==  "L'encanteri de la plaça"))}

                    </View>
                    <View style={{flex: 12}}>
                    {Itinerari(RouterData.route.params.itineraris.find(e => e.Nom ==  "La llum de Sant Ximplí"))} 
                    </View>
                </View>

                {/* Footer */}
                <View style={{flex:10}}>
                    {Menu(RouterData)}
                </View>
            </ImageBackground>
        </View>
    )
} 

export default Itineraris;
