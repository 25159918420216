import { Dimensions, StyleSheet } from 'react-native';
import constants from '../Config/constants';

/* Styles for the component Home */

const style = StyleSheet.create({
    fontFamily: 'Open Sans',

    backgroundImatges : {
        flex: 1,
        resizeMode: "cover",
        justifyContent: "center",
    },
    menuIcon:
    {
      width:'100%',
      height: "90%",
      alignSelf:'center',
      resizeMode:'contain'
    },
    touchableIcon:{
      flex: 1,
      width:"70%",
      alignItems:'center',
      alignSelf:'center'
    }
 

});
    
export default style; 