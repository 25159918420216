import React, { useState } from "react";
import { View,Text, ImageBackground, ScrollView, TextInput, Image, TouchableOpacity } from "react-native";
import PopUpQuizzLabel from "../../PopUps/PistaQuizzLabel";

import api from "../../../../Config/api_urls";
import globalstyle from "../../../../Styles/Globals/globals";
import style from "../../../../Styles/quizz";
import Menu from "../../Menu/Menu";

function QuizzLabel(RouterData) {
    let poiData = RouterData.route.params.itinerari.pois.find(e => e.id == RouterData.route.params.poi.id);
    const [punts, setPunts] = useState(4);
    const [numPista, setNumPista] = useState(0);
    let infoPista = poiData["Quizz"+RouterData.route.params.idioma].pistes[numPista] 
    const [continuar, setContinuar] = useState(null);
    const [pista, setPista] = useState(false);
    const [resposta, setResposta] = useState('');


   const norm = (text) => {
        if(text && text !== "") {
            return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z]/g, "").toLowerCase()
        }
        else {
            return ""
        }
    }
  
    const respondre = () => {
        poiData['Quizz'+RouterData.route.params.idioma].respostes.forEach(element => {
                    // si l'element es all la resposta sera sempre correcte
            if(element.valid === "all") {
                setContinuar(true);
            }

          if(typeof(element.valid) == "number")
          {
            if(element.valid == resposta)
            {
              setContinuar(true);
            }else
            {
              setPunts(punts-1);
              showPista(true);
              setContinuar(false);
            }
          }else
          {
            if(norm(element.valid) == norm(resposta))
            {
              setContinuar(true);
            }else
            {
                setPunts(punts-1);
                setPista(true);
                setContinuar(false);
             
            }
          }
        });
    }

    return (
        <View style={{flex:1}}>
          <ImageBackground source={{uri: api.api_url + RouterData.route.params.recursos.find(e => e.tipus_recurs == 'background_default')['imatge_recurs'].url}} style={style.backgroundImatges}>
                {/* Pregunta */}
                {pista ?  PopUpQuizzLabel(RouterData,pista,setPista,infoPista,numPista,setNumPista) : null}
                <View style={{flex:30,justifyContent:'center',alignItems:'center',alignSelf:'center'}}>
                    <View style={style.questionBackground}>
                        <ScrollView contentContainerStyle={style.scroll} showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
                            <View style={{justifyContent: 'center', alignItems: 'center'}}>
                                <Text style={style.question}>{poiData["Quizz" + RouterData.route.params.idioma].pregunta}</Text>
                            </View>
                        </ScrollView>
                    </View>
                </View>
                <View style={{flex:5}}/>
                <View style={{flex:40,justifyContent: 'center', alignItems: 'center'}}>
                    <View style={{flex:30,justifyContent:'center',alignItems:'center'}}>
                    <TextInput
                        multiline={true}
                        style={style.answerInput} 
                        numberOfLines={4}
                        onChangeText={(text) => setResposta(text)}
                        editable={!continuar}
                        value={resposta}/>

                    </View>
                    <View style={{flex:10,justifyContent:'center',alignItems:'center',width:'70%',height:'70%'}}>
                        {continuar != null ?
                        (
                            <Image resizeMode="contain" style={style.encert} 
                                source={
                                    continuar ?
                                        {uri: api.api_url + RouterData.route.params.recursos.find(e=>e.tipus_recurs == "resposta_encert")['imatge_recurs'].url}
                                        :
                                        {uri: api.api_url + RouterData.route.params.recursos.find(e=>e.tipus_recurs == "resposta_error")['imatge_recurs'].url}
                                }
                            />
                        )
                        :
                            null
                        }
                    </View>
                </View>
                <View style={{flex:10,justifyContent: 'center', alignItems: 'center'}}>
                    {
                        continuar ?
                        (
                            <TouchableOpacity onPress={()=>{
                                RouterData.route.params.currentItinerari.pois.find(e => e.id == RouterData.route.params.poi.id).punts = punts <= 0 ? 0 : punts;
                                RouterData.navigation.navigate('Premi',RouterData.route.params);
                            }}>
                                <Text style={globalstyle.botoContinuar}>{RouterData.route.params.recursos.find(e=> e.tipus_recurs == "text_continuar")['text_recurs'+RouterData.route.params.idioma]}</Text>
                            </TouchableOpacity>
                        )
                        :
                        (
                            <TouchableOpacity onPress={()=>{respondre()}}>
                                <Text style={globalstyle.botoContinuar}>{RouterData.route.params.recursos.find(e=> e.tipus_recurs == "text_respondre")['text_recurs'+RouterData.route.params.idioma]}</Text>
                            </TouchableOpacity>
                        )
                    }
                </View>
                <View style={{flex:5}}/>
                <View style={{flex:10}}>
                    {Menu(RouterData)}
                </View>
          </ImageBackground>

        </View>
    );
}

export default QuizzLabel;

// {pista ?  PopUpQuizzLabel(RouterData,pista,setPista,infoPista) : null}