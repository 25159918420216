import react from "react";
import api from "../../../Config/api_urls";
import {View, Image,TouchableOpacity,} from 'react-native';
import constants from "../../../Config/constants";

    /* //=> Les sagrades linies den Giuseppe (NO BORRAR PORTEN SORT)

        _handlePressButtonAsync = async () => {
            let result = await WebBrowser.openBrowserAsync('https://studio.playvisit.com/app/1547');
            console.log(result);
          };
    */
   
//todo: Mides de botons responsive
function Menu(RouterData) {

    const renderBoto = (navigate , icona_url) => {
        if(icona_url)
        {
            return (
                <TouchableOpacity style={{flex:0.3,alignSelf:'center'}} onPress={() => {
                    RouterData.navigation.navigate(navigate,RouterData.route.params);
                }}>
                    <Image source={{uri: api.api_url + icona_url}} style={{width: 30, height: 45,alignSelf:'center'}} />
                </TouchableOpacity>
            );
        }
    }

  return (
    <View style={[{flex:1,flexDirection:'row'},{backgroundColor:constants.colorFonsMenu}]}>
        {renderBoto('Relats', RouterData.route.params.recursos.find(e => e.tipus_recurs === '00_BotoMenu1')['imatge_recurs'].url )}
        {renderBoto('Mapa', RouterData.route.params.recursos.find(e => e.tipus_recurs === '00_BotoMenu2')['imatge_recurs'].url )}
        {renderBoto('VistaVR', RouterData.route.params.recursos.find(e => e.tipus_recurs === '00_BotoMenu3')['imatge_recurs'].url )}
        {renderBoto('Credits', RouterData.route.params.recursos.find(e => e.tipus_recurs === '00_BotoMenu4')['imatge_recurs'].url)}
        {renderBoto('Perfil', RouterData.route.params.recursos.find(e => e.tipus_recurs === '00_BotoMenu5')['imatge_recurs'].url)}
    </View>
  );
  
}

export default Menu;