import React, { useEffect, useState } from "react";
import { View, Text, ImageBackground, ScrollView, Image, TouchableOpacity, Modal, Dimensions } from "react-native";
import * as WebBrowser from "expo-web-browser";

import style from "../../../Styles/popUp";
import api from "../../../Config/api_urls";
import constants from "../../../Config/constants";
import { postStadistic } from "../../../Config/petitions"
import AsyncStorage from "@react-native-async-storage/async-storage";


/*
    textSocial = {textSocial : text que volem compartir , peticio : text que volem mostrar al cenobify }
*/

function XarxesSocials(RouterData,token,textSocial)
{
    const _handlePressButtonAsync = async (link) => {
        let result = await WebBrowser.openBrowserAsync(link);
    };

    return(
        <View style={[style.xarxesContainer]}>
            <View style={{justifyContent: 'center',alignItems:'center',flexDirection:'row'}}>
               
                {/* FB */}
                <TouchableOpacity style={{height: (Dimensions.get('screen').height * (13 / 15) * 0.67) * 0.11, width: (Dimensions.get('screen').height * (13 / 15) * 0.67) * 0.11}} onPress={
                    ()=>{_handlePressButtonAsync("https://www.facebook.com/sharer/sharer.php?u="+constants.hrefXarxesProjecte+"&t="+
                    textSocial.textSocial );
                    // postStadistic({token:token, tipus_peticio: "Compartir xarxes", peticio: "Compartir en Facebook( "+ textSocial.peticio + " )" })
                    }}
                >
                    <Image style={{width:'100%',height: "90%",alignSelf:'center',resizeMode:'contain'}} source={{uri: api.api_url + RouterData.route.params.recursos.find(e=> e.tipus_recurs == "perfil_facebook")["imatge_recurs"].url}} />    
                </TouchableOpacity>
                
                {/* TW  */}
                <TouchableOpacity style={{height: (Dimensions.get('screen').height * (13 / 15) * 0.67) * 0.11, width: (Dimensions.get('screen').height * (13 / 15) * 0.67) * 0.11}} onPress={
                    ()=>{_handlePressButtonAsync("https://twitter.com/intent/tweet?text="+
                    textSocial.textSocial 
                    );
                    // postStadistic({token:token, tipus_peticio: "Compartir xarxes", peticio: "Compartir en Twitter( "+ textSocial.peticio + " )" })
                }}
                >
                    <Image style={{width:'100%',height: "90%",alignSelf:'center',resizeMode:'contain'}} source={{uri: api.api_url + RouterData.route.params.recursos.find(e=> e.tipus_recurs == "perfil_twitter")["imatge_recurs"].url}} />   
                </TouchableOpacity>

                {/* WSP  */}
                <TouchableOpacity style={{height: (Dimensions.get('screen').height * (13 / 15) * 0.67) * 0.11, width: (Dimensions.get('screen').height * (13 / 15) * 0.67) * 0.11}} onPress={
                    ()=>{_handlePressButtonAsync("whatsapp://send?text="+
                    textSocial.textSocial 
                    );
                    // postStadistic({token:token, tipus_peticio: "Compartir xarxes", peticio: "Compartir en Whatsapp( "+ textSocial.peticio + " )" })
                    }}
                >
                    <Image style={{width:'100%',height: "90%",alignSelf:'center',resizeMode:'contain'}} source={{uri: api.api_url + RouterData.route.params.recursos.find(e=> e.tipus_recurs == "perfil_whatsapp")["imatge_recurs"].url}} />   
                </TouchableOpacity>
            </View>
        </View>
    )
}

export default XarxesSocials;
