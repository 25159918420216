import AsyncStorage from "@react-native-async-storage/async-storage";
import React, { useEffect,useState } from "react";
import { View, Text, ImageBackground, ScrollView, Image, TouchableOpacity } from "react-native";

import style from "../../../Styles/itineraris";
import api from "../../../Config/api_urls";
import Menu from "../Menu/menu";
import ReactMarkdown from "react-markdown";
import globalstyle from "../../../Styles/Globals/globals";


function InfoItinerari(RouterData) {
    var currentItinerari;
    console.log(RouterData.route.params);
    const [itinerariActual, setItinerariActual] = useState("");

    useEffect(() => {
        setItinerariActual(RouterData.route.params.itineraris.find(e => e.id == 35)); //Id posada de manera manual
    }, []);
    
    /* Init itinerari variables  */
    const navigate = () => {
        let puntsItinerari = 0;
        AsyncStorage.getItem("itinerari"+itinerariActual.id).then((Asyncitinerari) => {
            if(Asyncitinerari){
                currentItinerari = JSON.parse(Asyncitinerari);
                RouterData.navigation.navigate("Mapa",{...RouterData.route.params, currentItinerari : currentItinerari});
            }else{
                RouterData.route.params.projecte.premis.forEach(element => {
                    if(element.itinerari == RouterData.route.params.itinerari.id)
                    {
                        if(element.punts != null && element.punts != undefined && element.punts != "")
                        {
                            puntsItinerari += parseInt(element.punts);
                        }
                    }
                });
        
                currentItinerari = {
                    id: RouterData.route.params.itinerari.id,
                    comencat : false,
                    finalitzat : false,
                    currentOrder: 1,
                    pois : [],
                    puntsTotals: 0,
                    puntsItinerari : puntsItinerari
                }
                
                RouterData.route.params.itinerari.pois.forEach(e => {
                    currentItinerari.pois.push({
                        id: e.id,
                        isFirst: false,
                        order: e.Order,
                        isLast: false,
                        done : false,
                        popUpLoaded : false,
                        location : [e.lon,e.lat] // OLD PROJECTES ESTAN GIRADES !!
                    })
                })
        
                currentItinerari.pois.sort((a,b) => a.order - b.order);
                currentItinerari.pois[0].isFirst = true;
                currentItinerari.pois[currentItinerari.pois.length-1].isLast = true;
                // AsyncStorage.setItem("itinerari"+RouterData.route.params.itinerari.id, JSON.stringify(currentItinerari));
                RouterData.navigation.navigate("Mapa",{...RouterData.route.params, currentItinerari : currentItinerari});
            }
        })
    }
    
    return (
        <View style={{ flex: 1,flexGrow:1}}>
            <ImageBackground source={{ uri: api.api_url + RouterData.route.params.recursos.find(e => e.tipus_recurs == '02_background_presentacio')['imatge_recurs' + RouterData.route.params.idioma].url }} style={globalstyle.backgroundImatges}>
                {/* Header */}
                {/* Body */}
                {/* <View style={{flex:60}}> */}
                  <View style={{flex: 30}}>
                    
                  </View>

                {/* Footer */}
                <View style={{flex:25,alignSelf:'center',alignItems:'center',justifyContent:'center'}}>
                    <TouchableOpacity onPress={ () => navigate() } style={{}}>
                        <Text style={globalstyle.botoContinuar}>{RouterData.route.params.recursos.find(e => e.tipus_recurs == 'text_continuar')["text_recurs"+ RouterData.route.params.idioma]}</Text>
                    </TouchableOpacity>
                </View>
                {/* <View style={{flex:5}}>
                    {Menu(RouterData)}
                </View> */}
            </ImageBackground>
        </View>
    );
}

export default InfoItinerari;