import React from "react"
import * as RNLocalize from "react-native-localize"

/* Get languaje of the website */

export default function getIdioma(){
    /*let locales = RNLocalize.getLocales()
    let idioma = locales[0].languageTag
    //only catala for testing
    switch(idioma.substring(0,2)){
        case "es":
            return "_ES";
        case "en":
            return "_EN";
        // case "fr":
        //     return "_FR";
        case "ca":
            return "";
        default:
            return "";
    }*/
    return "";
}


