import React, { useState } from "react";
import {View,Text,ImageBackground,ScrollView,Image,TouchableOpacity,Modal} from "react-native";

import style from "../../../Styles/popUp";
import api from "../../../Config/api_urls";
import globalstyle from "../../../Styles/Globals/globals";
import constants from "../../../Config/constants";
import ReactMarkdown from "react-markdown";

function PopUpMapaHistoria(RouterData,poi,modalHistoria,setModalHistoria)
{

    const jocNavigate = () => { //todo ajuntar amb la funcio de pois
        setModalHistoria(false);
        RouterData.route.params.currentItinerari.pois.find(e => e.id == poi.id).popUpLoaded = true;
        RouterData.route.params.currentItinerari.comencat = true;
        switch(poi.Tipus)
        {
            case "Joc360":
                RouterData.navigation.navigate('CheckIn',{...RouterData.route.params,poi});
                break;
            case "Info":
                RouterData.navigation.navigate('CheckIn',{...RouterData.route.params,poi});
                break;
            case "Checkin":
                RouterData.navigation.navigate('CheckIn',{...RouterData.route.params,poi});
                break;
            case "Quizz":
                RouterData.navigation.navigate('Quizz',{...RouterData.route.params,poi});
                break;
            case "QuizzImatges":
                RouterData.navigation.navigate('Quizz',{...RouterData.route.params,poi});
                break;
            case "QuizzLabel":
                RouterData.navigation.navigate('QuizzLabel',{...RouterData.route.params,poi});
                break;
            case "DragAndDrop":
                RouterData.navigation.navigate('DragAndDrop',{...RouterData.route.params,poi});
                break;
            default:
                console.warn("Error joc not found");
                break;
        }
    }

    return(
        <Modal animationType="fade" transparent={true} visible={modalHistoria}>
            <View style={style.modalOutBackground}>
                <View style={[style.caixaPopUp,{height:"80%"}]}>
                    <ImageBackground source={{uri: api.api_url + RouterData.route.params.recursos.find(e => e.tipus_recurs == 'background_default')['imatge_recurs'].url}} style={style.backgroundImatges}>
                        
                        {/* Close button */}
                        <TouchableOpacity style={globalstyle.crossBox} onPress={()=>{setModalHistoria(false)}}>
                            <Image source={{uri: api.api_url + RouterData.route.params.recursos.find(e => e.tipus_recurs == 'pop_up_close_icon')['imatge_recurs'].url }} style={style.cross} />
                        </TouchableOpacity>
                        
                        {/* Header */}
                        <View style={{flex:20,alignItems:'center',width:'90%',justifyContent:'center',alignSelf:'center'}}>
                            <Text style={[style.popUpTitleInicial]}> {poi['Nom'+RouterData.route.params.idioma]}</Text>
                        </View>

                        {/* Body */}
                            { poi['imatgeHistoria'] ?
                                (
                                    <View style={{flex:60}}>
                                        <View style={{flex:30}}>
                                            <Image source={{uri: api.api_url + poi['imatgeHistoria'].url}} style={style.popUpMapaImatge} />
                                        </View>
                                        <View style={{flex:30,alignItems:'center',alignSelf:'center'}}>
                                            <ScrollView style={[style.descriptionContainerHistoria,{alignSelf:'center'}]}>
                                                <ReactMarkdown >{poi['textHistoria'+RouterData.route.params.idioma] ? (poi['textHistoria'+RouterData.route.params.idioma]) : (null) }</ReactMarkdown>
                                            </ScrollView>
                                        </View>
                                    </View>
                                )
                                :
                                (
                                    <View style={{flex:60,alignItems:'center',alignSelf:'center'}}>
                                         <ScrollView style={[style.popUpMapaText,{alignSelf:'center'}]}>
                                            <ReactMarkdown>{poi['textHistoria'+RouterData.route.params.idioma] ? (poi['textHistoria'+RouterData.route.params.idioma]) : (null) }</ReactMarkdown>                                        
                                        </ScrollView>
                                    </View>
                                )
                            }

                        {/* Footer */}
                        <View style={{flex:20,alignItems:'center',alignSelf:'center',justifyContent:'center'}}>
                            <TouchableOpacity onPress={()=>{jocNavigate()}}>
                                <Text style={globalstyle.botoContinuar}>{RouterData.route.params.recursos.find(e => e.tipus_recurs == 'text_continuar')['text_recurs'+ RouterData.route.params.idioma]}</Text>
                            </TouchableOpacity> 
                        </View> 

                    </ImageBackground>
                </View>
            </View>
        </Modal>
    )
}

export default PopUpMapaHistoria;