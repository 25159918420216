import { StyleSheet } from 'react-native';
import constants from '../Config/constants';

/* Styles for the component Login */

const style = StyleSheet.create({
    fontFamily: 'Open Sans',

    textNom: {
      fontSize: "2.5vh",
      fontFamily: 'Gothic',
      width: '60%',
      height: 40,
      justifyContent: "center",
      alignSelf: 'center',
      borderRadius: 1,
      textAlign: 'left',
      fontWeight: 'bold',
      backgroundColor: 'white',
      color: constants.colorText,
      paddingLeft: 10,
  },
 

});
    
export default style; 