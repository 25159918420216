import { StyleSheet } from 'react-native';
import { Dimensions } from 'react-native';

import { colorCorporatiu , colorBotons,  } from '../Config/constants'

/* Styles for the component Trucada */

const style = StyleSheet.create({
    fontFamily: 'Open Sans',

    text: {
        fontSize: "4vh",
        color: "white",
        textAlign: "center",
        // justifyContent: "center",
        alignItems: "center",
        // fontFamily: "Leckerli One",
        // textShadow: "-0.5vh -0.5vh 0.5vh #f2eed7, 0.5vh -0.5vh 0.5vh #f2eed7, -0.5vh 0.5vh 0.5vh #f2eed7, 0.5vh 0.5vh 0.5vh #f2eed7"
    },
    videoContainer:{
        height: '100%',
        width: '100%',
        backgroundColor: 'black',
        alignSelf: 'center',
        justifyContent: 'center',
    }
});
    
export default style; 