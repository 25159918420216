import react from "react";

import api from "../../Config/api_urls";


function getMainData()
{
    return new Promise((resolve, reject) => {
        fetch(api.basMagia, {
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                resolve(responseJson);
            }
            )
            .catch((error) => {
                reject(error);
            }
            );
    }
    );
}

function getRelatsData()
{
    return new Promise((resolve, reject) => {
        fetch(api.getRelats, {
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                resolve(responseJson);
            }
            )
            .catch((error) => {
                reject(error);
            }
            );
    }
    );
}

function getPoisData()
{
    return new Promise((resolve, reject) => {
        fetch(api.basMagia_poi_all, {
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                resolve(responseJson);
            }
            )
            .catch((error) => {
                reject(error);
            }
            );
    }
    );
}

export { getMainData, getRelatsData, getPoisData };