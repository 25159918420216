
import React, { useState } from "react";
import { View, Text, ImageBackground, ScrollView, Image, TouchableOpacity,Modal } from "react-native";

import style from "../../../Styles/popUp";
import globalstyle from "../../../Styles/Globals/globals";
import api from "../../../Config/api_urls";


function PopUpInicial(RouterData,modalVisible,setModalVisible){

 
 
    return(
        <Modal animationType="fade" transparent={true} visible={modalVisible}>
            <View style={style.modalOutBackground}>
                <View style={[style.caixaPopUp,{height:"80%"}]}>
                    <ImageBackground source={{uri: api.api_url + RouterData.route.params.recursos.find(e => e.tipus_recurs == '05_background_tipus2')['imatge_recurs'].url}} style={style.backgroundImatges}>
                        {/* Close button */}
                        <TouchableOpacity style={globalstyle.crossBox} onPress={()=>{setModalVisible(false)}}>
                            <Image source={{uri: api.api_url + RouterData.route.params.recursos.find(e => e.tipus_recurs == 'pop_up_close_icon')['imatge_recurs'].url }} style={style.cross} />
                        </TouchableOpacity>
                        
                        {/* Header */}
                        <View style={{flex:40,alignItems:'center',width:'85%'}}>
                            {/* <ImageBackground style={{width:'100%',height:'100%',alignItems:'center',justifyContent:'center'}} resizeMode={'contain'}  source={{uri: api.api_url + RouterData.route.params.recursos.find(e => e.tipus_recurs == 'fons_text_default')['imatge_recurs'].url}}>
                                <Text style={style.popUpTitleInicial}>{RouterData.route.params.itinerari["Nom"+ RouterData.route.params.idioma]}</Text>    
                            </ImageBackground> */}
                        </View>
                        
                        {/* Body */}
                        <View style={{flex:50,alignItems:'center',alignSelf:'center'}}>
                            <Text style={style.popUpMapaText}>{RouterData.route.params.recursos.find(e => e.tipus_recurs == 'popup_inici_itinerari_2')['text_recurs'+ RouterData.route.params.idioma]}</Text>
                            <Image style={style.imageFlag} source={{uri: api.api_url + RouterData.route.params.recursos.find(e => e.tipus_recurs == 'itinerari_punt_inici')['imatge_recurs'].url}} />
                        </View>

                        {/* Footer */}
                        <View style={{flex:10}}>
                            <TouchableOpacity  onPress={()=>{setModalVisible(false)}}>
                                <Text style={globalstyle.botoContinuar}>{RouterData.route.params.recursos.find(e => e.tipus_recurs == 'text_continuar')['text_recurs'+RouterData.route.params.idioma]}</Text>
                            </TouchableOpacity>
                        </View>
                    </ImageBackground>    
                </View>
            </View>
        </Modal>
    )
}

export default PopUpInicial;