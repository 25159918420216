import react, { useEffect, useState } from "react";
import { View, Text, ImageBackground, Image, TouchableOpacity, Dimensions, TextInput, ScrollView } from 'react-native';

import globalstyle from "../../../Styles/Globals/globals";
import style from "../../../Styles/Presentacio";
import api from "../../../Config/api_urls";
import { postStadistic } from "../../../Config/petitions"
import AsyncStorage from "@react-native-async-storage/async-storage";
import ReactMarkdown from "react-markdown";
import Menu from "../Menu/Menu";



function Presentacio(RouterData) {
    return(
        <View style={{ flex: 1, maxWidth: '100%', maxHeight: '100%' }}>
        <ImageBackground source={{ uri: api.api_url + RouterData.route.params.recursos.find(e => e.tipus_recurs == '02_background_presentacio')['imatge_recurs' + RouterData.route.params.idioma].url }} style={globalstyle.backgroundImatges}>
            <View style={{flex: 30}}>

            </View>
            <View style={{ flex: 8, alignItems: 'center', justifyContent: 'center' }}>
                <TouchableOpacity onPress={() => { RouterData.navigation.navigate('Home', RouterData.route.params); }}>
                    <Text style={[globalstyle.botoContinuar]}>{RouterData.route.params.recursos.find(e => e.tipus_recurs == 'text_continuar')["text_recurs" + RouterData.route.params.idioma]}</Text>
                </TouchableOpacity>
            </View>
            <View style={{flex:5}}/>   
                    <View style={{flex:5}}>
                        {Menu(RouterData)}
                    </View>
        </ImageBackground>
    </View>

    );
    

}

export default Presentacio;