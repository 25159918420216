import React,{useState} from "react";
import { View, Text, ImageBackground, ScrollView, Image, TouchableOpacity, SafeAreaView, FlatList } from "react-native";
import CardFlip from 'react-native-card-flip';

import api from "../../../../Config/api_urls";
import globalstyle from "../../../../Styles/Globals/globals";
import style from "../../../../Styles/quizz";
import Menu from "../../Menu/Menu";

function Quizz(RouterData) {
    let poiData = RouterData.route.params.itinerari.pois.find(e => e.id == RouterData.route.params.poi.id);
    
    let cards = []
    const [continuar, setContinuar] = useState(false);
    const [punts, setPunts] = useState(4);

    const flipCard =(index,valid) => {
        if(!continuar){
            cards[index].flip({duration : 700,flipDiretion : 'y'});
            if(valid)
            {
                setContinuar(true);
            }else
            {
                setPunts(punts-1);
            }
        }
    }

    return (
       <View style={{flex:1}}>
            <ImageBackground source={{uri: api.api_url + RouterData.route.params.recursos.find(e => e.tipus_recurs == 'background_default')['imatge_recurs'].url}} style={style.backgroundImatges}>
                {/* Pregunta */}
                <View style={{flex:30,justifyContent:'center',alignItems:'center',alignSelf:'center'}}>
                    <View style={style.questionBackground}>
                        <ScrollView contentContainerStyle={style.scroll} showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
                            <View style={{justifyContent: 'center', alignItems: 'center'}}>
                                <Text style={style.question}>{poiData["Quizz" + RouterData.route.params.idioma].pregunta}</Text>
                            </View>
                        </ScrollView>
                    </View>
                </View>
                {/* Respostes */}
                <View style={{flex:50,justifyContent: 'center', alignItems: 'center'}}>
                    <SafeAreaView style={style.safeAreaAnswer}>
                        <FlatList
                            data={poiData["Quizz" + RouterData.route.params.idioma].respostes}
                            style={{flex:1}}
                            showsVerticalScrollIndicator={false}
                            showsHorizontalScrollIndicator={false}
                            renderItem={({item,index}) => (
                               
                                <View style={{flexDirection: 'column', justifyContent: "center", margin: 1}}>
                                    <CardFlip style={style.cardContainer} ref={(card)=> cards.push(card) } > 
                                        <TouchableOpacity style={style.containerAnswer} onPress={()=>{flipCard(index,item.valid)}}>
                                          {
                                            poiData.Tipus == 'Quizz' ?
                                            (
                                                <Text style={style.answer}>{item["answer"]}</Text>
                                            )
                                            :
                                            (
                                                <Image resizeMode="contain" 
                                                    style={style.questionImage}
                                                    source={{uri: api.api_url + poiData["resposta_imatge_"+ item.id].url}}
                                                />
                                            )
                                          }  
                                        </TouchableOpacity>
                                        <TouchableOpacity style={style.containerAnswerBack}>
                                            <Image
                                                resizeMode="contain"
                                                style={style.solucio}
                                                source={item.valid ? {uri: api.api_url + RouterData.route.params.recursos.find(e => e.tipus_recurs == 'resposta_encert')['imatge_recurs'].url} : {uri: api.api_url + RouterData.route.params.recursos.find(e => e.tipus_recurs == 'resposta_error')['imatge_recurs'].url}}
                                            />
                                        </TouchableOpacity>
                                    </CardFlip>
                                </View>
                            )}

                            numColumns={2}
                            keyExtractor={(item, index) => index}
                            />   
                    </SafeAreaView>
                </View>
                
                <View style={{flex:10,justifyContent:'center'}}>
                    {continuar ?
                    (
                        <TouchableOpacity 
                            onPress={()=>{
                                RouterData.route.params.currentItinerari.pois.find(e => e.id == RouterData.route.params.poi.id).punts = punts;
                                RouterData.navigation.navigate('Premi',RouterData.route.params);
                            }}
                        >
                            <Text style={globalstyle.botoContinuar}>{RouterData.route.params.recursos.find(e => e.tipus_recurs == "text_continuar")['text_recurs'+RouterData.route.params.idioma]}</Text>
                        </TouchableOpacity>
                    )
                    :
                    null
                    }
                </View>
                <View style={{flex:10}}>
                    {Menu(RouterData)}
                </View>
            </ImageBackground>
       </View>
    );
}

export default Quizz;