/* Contains all the constants used in the application pels diferents projectes */

var hrefXarxesProjecte = 'https://joc.elboixdesallent.cat/'
var encodedhrefXarxesProjecte = 'https%3A%2F%2Fjoc.elboixdesallent.cat';
const hrefProjecteUrl = 'http://www.sallentturisme.cat/';
const colorCorporatiu = 'white';
const colorBotons = '#f4efa2';
const colorText = 'black';
const colorFonsMenu = '#f2eed7'
const colorTitols = 'white';
const colorWarningMapa = '#ff0000';

export default {
    hrefXarxesProjecte : hrefXarxesProjecte,
    encodedhrefXarxesProjecte : encodedhrefXarxesProjecte,
    colorCorporatiu : colorCorporatiu,
    colorBotons : colorBotons,
    colorText : colorText,
    colorFonsMenu : colorFonsMenu,
    colorTitols : colorTitols,
    colorWarningMapa : colorWarningMapa,
    hrefProjecteUrl : hrefProjecteUrl,
    
    colorItinerari : '#009498',
    colorTextItinerari : 'white',
    colorItinerariComencat : 'green',
    colorItinerariFinalitzat : 'grey',
};